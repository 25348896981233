import { render, staticRenderFns } from "./CompaniesBilling.vue?vue&type=template&id=5b8ac8d1&"
import script from "./CompaniesBilling.vue?vue&type=script&lang=js&"
export * from "./CompaniesBilling.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports