<template>
<v-container fluid class="pa-0">
<!-- Formulaire -->
    <v-card fluid class="ma-2 pa-3 elevation-0 rounded-xxl micorise-v-card">
    <v-card-title >
        {{ (item.id) ? 'Fiche de ' + item.lastName + ' ' + item.firstName : 'Nouvel utilisateur' }}
        <v-spacer></v-spacer>
        <v-chip outlined color="secondary">
                <v-icon left>mdi-information-outline</v-icon>Modification: {{ $date(item.updatedAt).format('DD MMMM YYYY') }}
        </v-chip>
    </v-card-title>
    <v-form ref="form" v-model="validForm" lazy-validation>
    <v-row no-gutters>
    <v-col cols="12" md="4" class="pr-2">
        <v-text-field :rules="nameRules" autocomplete="off" outlined dense class="rounded-lg" label="Nom"    v-model="item.lastName" ></v-text-field>
        <v-text-field :rules="nameRules" autocomplete="off" outlined dense class="rounded-lg" label="Prénom" v-model="item.firstName"></v-text-field>
        <v-text-field autocomplete="off" outlined dense class="rounded-lg" label="Login"  v-model="item.username"></v-text-field>
        <v-text-field autocomplete="off" outlined dense class="rounded-lg" label="Mot de passe" type="password" v-model="item.password" loading>
            <template v-slot:progress>
                <v-progress-linear :value="progress" :color="color" height="3" absolute></v-progress-linear>
            </template>
        </v-text-field>
    </v-col>
    <v-col cols="12" md="4" class="pr-2">
        <v-text-field autocomplete="off" outlined dense class="rounded-lg" label="Tel" v-model="item.phone"></v-text-field>
        <v-text-field autocomplete="off" outlined dense class="rounded-lg" label="Mobile" v-model="item.mobile"></v-text-field>
        <v-text-field :rules="emailRules" autocomplete="off" outlined dense class="rounded-lg" label="Courriel" v-model="item.email"></v-text-field>
        <v-select v-if="$root.$permissions?.User?.update" 
            outlined dense class="" :items="roleList" item-value="value" v-model="item.role" label="Role" single-line >
        </v-select>
    </v-col>
    <v-col cols="12" md="4" class="pr-2">
        <v-btn v-if="$root.$permissions?.User?.update"  outlined color="primmary" class="mt-3" @click="resetPasswordMail()">
            <v-icon left>mdi-mail</v-icon>Envoyer le mail
        </v-btn>
    </v-col>
    <v-col cols="12" md="4" class="pr-2"></v-col>
    <v-col cols="12" md="4" class="pr-2">
        <AutocompleteMulti v-if="!loading.fetchData"
            :title="'Société'"
            :listObject.sync="item.companyId" :listParent.sync="listCompanies" 
            ressource="companies"
            :fields="{ 
                index: 'cpy_id', text:'cpy_name', autofocus: true, outlined: true, dense: true,
                search:'cpy_name', multiSelect:false, prefetch: false, params:'extended=viewall'
        }"></AutocompleteMulti>
    </v-col>
    </v-row>
    </v-form>
    <v-card-actions>
        <v-btn class="rounded-xl" large color="red" v-if="item.uid" @click="deleteUser(item)"><v-icon title="Supprimer">mdi-delete</v-icon></v-btn>
        <v-spacer></v-spacer>
        <v-btn class="rounded-xl" large color="success" :loading="loading.fetchData" @click.native="saveUser()" :disabled="!validForm">Enregistrer</v-btn>
        <v-btn class="rounded-xl" large color="primmary" href="/#/users/">Retour</v-btn>
    </v-card-actions>
</v-card>
</v-container>
</template>

<script>
import AutocompleteMulti from '@/components/ui/AutocompleteMulti'
import { mapState, mapActions } from 'pinia'
import { useUserStore } from '@/stores/user'
import { cacheGetters } from "@/stores/cache.module"

export default {
    name: 'User-Edit',
    components: { AutocompleteMulti },
    computed: {
        ...mapState(useUserStore, {item: 'item', roleList: 'getAuthorizedRolesArray', me: 'me' }),
        progress () {
            return (this.item.password) ? Math.min(100, this.item.password.length * 12.5) : 0
        },
        color () {
            return ['error', 'warning', 'success'][Math.floor(this.progress / 50)]
        },
    },
    data: function () {
        return {
            loading: { fetchData: true },
            cacheState: cacheGetters.getStates('GEN') || [],
            listCompanies: [],
            validForm: true,
            nameRules: [ v => !!v || 'Le champ doit être renseigné', v => (v && v.length <= 100) || 'Doit être inférieur à 100 charactères', ],
            emailRules: [ v => !!v || 'E-mail requis', v => /.+@.+\..+/.test(v) || 'E-mail doit être valide', ],
        }
    },
    async beforeMount () {
        this.listCompanies.push( this.me.usercompany )
        await this.fetchUser( this.$route.params.id, '?include=usercompany' )
        if( !this.item.id ){
            this.item.role = 'AGENT'
            this.item.companyId = this.me.companyId
            this.item.email = (this.me.usercompany.cpy_mail) ? Date.now() + this.me.usercompany.cpy_mail.slice(this.me.usercompany.cpy_mail.indexOf('@') ) : ''
        }
        this.loading.fetchData = false
    },
    methods: {
        ...mapActions(useUserStore, {fetchUser: 'fetchItem', updateItem: 'updateItem', storeItem: 'storeItem', resetPassword: 'resetPassword'}),
        async saveUser () {
            await this.$refs.form.validate()
            if( this.validForm ){
                // Enregistrement du formulaire
                this.loading.fetchData = true
                if( parseInt( this.$route.params.id ) > 0 ){
                    await this.updateItem()
                } else {
                    await this.storeItem()
                }
                this.loading.fetchData = false
                this.$router.push('users')
            }
        },
        async resetPasswordMail(){
            await this.resetPassword( this.item.id )
            this.$root.$toast({ color: 'success', text: 'Mail envoyé !' })
        },
        getCacheType: cacheGetters.getTypes,
    }
}
</script>