<template>
<v-card>
    <v-card-text>
    <v-layout row wrap>
        <v-flex xs12 class="pa-2">
            <v-text-field label="Nom" v-model="item.doc_name"></v-text-field>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-select 
                :items="types" item-text="typ_name" item-value="typ_id" 
                v-model="item.doc_typ_id" label="Type de document" >
            </v-select>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <!-- <v-select :items="table" item-text="table_name" item-value="table_id" v-model="item.doc_table_id" label="Association" disabled >
            </v-select> -->
            <img ref="imgSrc" :src="uploadedImageSrc" v-show="false" class="ma-3 elevation-9" />
            <v-img ref="resizedImage" max-width="100%" max-height="10em" style="cursor:pointer;" 
                v-show="uploadedImage" :src="resizedImage" aspect-ratio="1" class="ma-3 elevation-3 rounded-lg">
            </v-img> 
        </v-flex>
        <v-flex xs12 class="pa-2">
            <v-text-field label="Description" v-model="item.doc_description"></v-text-field>
        </v-flex>
        <v-flex xs12 class="pa-2">
            <v-file-input @change="getFileDocument" ref="inputUpload" v-model="item.obj_file" show-size label="Ajouter fichier / Prendre une photo"></v-file-input>
        </v-flex>
    </v-layout>
    </v-card-text>
    <v-divider light></v-divider>
    <v-card-actions>
        <v-menu v-if="item.eve_id > 0" v-model="optionMenu" offset-y transition="slide-x-transition" bottom right >
            <template v-slot:activator="{ on }">
            <v-btn v-on="on" outlined class="ml-0 pa-0" style="min-width: 5px;" color="grey"><v-icon>mdi-dots-vertical</v-icon></v-btn>
            </template>
            <v-btn class="ma-0" color="error" @click.native="deleteDocument()" :disabled="saveProgress">
                <v-icon left>mdi-trash-can</v-icon>Supprimer
            </v-btn>
        </v-menu>
        <v-spacer></v-spacer>
        <v-btn rounded ref="saveDoc"
            color="success" 
            :loading="saveProgress" @click.native="saveDocument()" :disabled="saveProgress">
            Enregistrer
        </v-btn>
        <v-btn rounded outlined color="grey" @click="switchModal('modalDocument', 0)">
            Fermer
        </v-btn>
    </v-card-actions>
</v-card>
</template>

<script>
import { mapState, mapWritableState, mapActions } from 'pinia'
import { useDocumentStore } from '@/stores/document'
import { useTypeStore } from '@/stores/type'
import { useStateStore } from '@/stores/state'

import { bus } from '@/plugins/bus'
import Pica from "pica"

export default {
    name: 'document-edit',
    props: ['toast', 'xxxId', 'docId', 'docTable', 'docType', 'switchModal', 'withFile'],
    computed: {
        ...mapWritableState(useDocumentStore, {item: 'item' }),
        ...mapState(useTypeStore, {typeListByGroup: 'itemListByGroup', typeListById: 'itemListById' }),
        ...mapState(useStateStore, {stateListByGroup: 'itemListByGroup', stateListById: 'itemListById' }),
    },
    data: function () {
        return {
            dateMenu: { date_start:false, date_end:false },
            saveProgress: false,         // Enregistrement en cours...
            states : [],
            types : [],
            table : [
                { table_name: 'Site',                table_id: 'trs' },
                { table_name: 'Evenement',           table_id: 'eve' },
            ],
            optionMenu: false,
            uploadedImage: false,
            uploadedImageSrc: '',
            resizedImage: '',
            resizeImage: false
        }
    },
    async mounted() {
        this.types = this.types.concat( this.typeListByGroup['DOC'] )
        if( this.docId !== undefined && this.docId > 0 ){
            this.item.doc_id = this.docId
            await this.fetchItem(this.docId)
        } else {
            this.fetchItem()
            this.item.doc_id       = 0
            this.item.doc_typ_id   = (this.docType) ? this.docType.substring(0, 5) : this.types[0].typ_id
            this.item.doc_table_id = this.docTable
            this.item.doc_xxx_id   = this.xxxId
            this.item.doc_usr_id_created   = 1
            if( this.withFile !== undefined ){
                this.item.doc_name = this.withFile.name
                this.item.obj_file = this.withFile
                this.getFileDocument(this.withFile)
            }
        }
    },
    methods: {
        ...mapActions(useDocumentStore, { fetchItem: 'fetchItem', updateItem: 'updateItem', storeItem: 'storeItem', destroyItem:'destroyItem' }),
        async saveDocument(){
            if( this.item.doc_id !== undefined && this.item.doc_id > 0 ){
                this.updateItem().then( () => {
                    this.switchModal('modalDocument', 0, true)
                })
            } else {
                this.storeItem().then( () => {
                    this.switchModal('modalDocument', 0, true)
                    bus.$emit('document:save', this.item)
                })
            }
        },
        deleteDocument(){
            this.deleteDocumentDocument( () => {
                this.switchModal('modalDocument', 0, true)
            })
        },
        getFileDocument(file){
            this.$refs.saveDoc.$el.focus()
            if( this.item.doc_name.length == 0 ){
                let nameTab = file.name.split(".")
                this.item.doc_name = nameTab[0]
            }
            // if photo but not logo !!
            if( (file.type == 'image/jpeg' || file.type == 'image/png') && this.item.doc_typ_id !== 'DOC04' ){
            //    this.item.doc_typ_id = 'DOC03'
                this.handleImageImport(file)
            }
            this.item.doc_filename = file.name
        },
        handleImageImport(file){
            this.resizeImage      = true
            const pica            = Pica();
            //var files             = this.$refs.inputUpload.files
            this.uploadedImageSrc = window.URL.createObjectURL(file)

            setTimeout( () => {
                let dim = this.calculateAspectRatioFit(this.$refs.imgSrc.width, this.$refs.imgSrc.height, 720, 1080)

                const resizedCanvas  = document.createElement("canvas")
                resizedCanvas.height = dim.height
                resizedCanvas.width  = dim.width

                pica.resize(this.$refs.imgSrc, resizedCanvas).then(result => {
                    this.resizedImage  = result.toDataURL()
                    pica.toBlob(result, 'image/jpeg', 0.85).then( (blob) => {
                        blob.name = file.name
                        this.item.doc_name = file.name
                        this.item.obj_file = blob

                        // Update states
                        // this.resizeImage   = false
                        this.uploadedImage = true
                        // this.item.doc_id = null
                    })
                })
                .catch(error => {
                    console.log(error);
                });
            }, 500)

        },
        calculateAspectRatioFit(srcWidth, srcHeight, maxWidth, maxHeight) {
            var ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
            return { width: srcWidth * ratio, height: srcHeight * ratio };
        },
    },
    components: { }
}
</script>