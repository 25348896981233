<template>
<v-container fluid class="pa-0">
    <PageHeader :contextStyle="contextStyle" >
        <!-- <template #right><v-btn small rounded color="primary" href="#/users/0">Ajouter un utilisateur</v-btn></template> -->
    </PageHeader>

    <UsersForm v-if="( $route.params.id !== undefined && parseInt( $route.params.id ) >= 0 )"/>
    <v-card v-else class="ma-4 rounded-lg">
        <UsersList />
    </v-card>
</v-container>
</template>

<script>
import PageHeader from '@/components/ui/PageHeader'
import UsersList from '@/components/users/UsersList'
import UsersForm from '@/components/users/UsersForm'

export default {
    name: 'user-page',
    components: { PageHeader, UsersList, UsersForm },
    props: ['contextStyle'],
    data () {
        return { }
    },
    methods: {

    }
}
</script>

<style lang="css">

</style>