import ApiService from '@/services/api'
import { defineStore } from 'pinia'
//import { taskUpdateService } from '@/rxjsServices';

const ENDPOINT = '/filters/'
const ITEM_ID  = 'fil_id'

export const useFilterStore = defineStore('filters', {
    //storage option
    persist: true,
    // convert to a function
    state: () => ({
        item: {
            min_tsk_date_plan: null,
            max_tsk_date_plan: null,
            tsk_tty_id: [],
            tsk_sta_id: [],
            sit_ent_id: [],
        },
        itemList: []
    }),
    getters: {
        getUrlFiter(){
            let urlFilter = ''
            urlFilter += (this.item.min_tsk_date_plan) ? '&min_tsk_date_plan=' + this.item.min_tsk_date_plan : ''
            urlFilter += (this.item.max_tsk_date_plan) ? '&max_tsk_date_plan=' + this.item.max_tsk_date_plan : ''
            urlFilter += (this.item.tsk_tty_id.length) ? '&tsk_tty_id=' + this.item.tsk_tty_id.join(',') : ''
            urlFilter += (this.item.tsk_sta_id.length) ? '&tsk_sta_id=' + this.item.tsk_sta_id.join(',') : ''
            urlFilter += (this.item.sit_ent_id.length) ? '&sit_ent_id=' + this.item.sit_ent_id.join(',') : ''
            return urlFilter
        }
    },
    actions: {
    fetchItem (id, options) {
        return new Promise( (resolve, reject) => {
            options = (options) ? '/' + options : ''
            if( id && id > 0 ){
                ApiService.get(ENDPOINT + id + options).then( (response) => {
                    if( !response.data.data.fil_customfields || Object.prototype.toString.call( response.data.data.fil_customfields ) === '[object Array]' ) {
                        response.data.data.fil_customfields = {}
                    }
                    this.SET_ITEM(response.data.data)
                    resolve(this.item)
                }).catch( (error) => { 
                    reject(error)
                })
            } else {
                this.RESET_ITEM()
                resolve(this.item)
            }
        })
    },
    fetchItems (options) {
        return new Promise( (resolve, reject) => {
            options = (options) ? options : ''
            ApiService.get( ENDPOINT + options ).then( (response) => {
                this.metaTask = response.data.meta
                if( Object.prototype.toString.call( response.data.data ) === '[object Array]' ) {
                    this.SET_ITEMS(response.data.data)
                }
                resolve('resolved')
            }).catch( (error) => { 
                reject(error)
            })
        })
    },
    storeItem () {
        return new Promise( (resolve, reject) => {
            ApiService.post( ENDPOINT, this.item ).then( (response) => {
            this.SET_ITEM(response.data.data)
            resolve(this.item)
            }).catch( (error) => { 
            reject(error)
            })
        })
    },
    updateItem () {
        return new Promise( (resolve, reject) => {
            ApiService.put( ENDPOINT + this.item[ITEM_ID], this.item ).then( (response) => {
            this.SET_ITEM(response.data)
            resolve(this.item)
            }).catch( (error) => { 
            reject(error)
            })
        })
    },
    destroyItem (id) {
        return new Promise( (resolve, reject) => {
            this.item[ITEM_ID] = (id) ? id : this.item[ITEM_ID]
            ApiService.delete(ENDPOINT + this.item[ITEM_ID]).then( () => {
                this.RESET_ITEM()
            resolve('resolved')
            }).catch( (error) => { 
            reject(error)
            })
        })
    },
    //***************************MUTATIONS ***************************************/
    removeDates(){
        this.item.min_tsk_date_plan = null
        this.item.max_tsk_date_plan = null
    },
    //***************************MUTATIONS ***************************************/
    SET_ITEM (item) {
      this.item = item
    },
    SET_ITEMS (itemList) {
      this.itemList = itemList
    },
    RESET_ITEM () {
        this.item.min_tsk_date_plan = null
        this.item.max_tsk_date_plan = null
        this.item.tsk_tty_id.splice(0)
        this.item.tsk_sta_id.splice(0)
        this.item.sit_ent_id.splice(0)
    }
  }
})
