<template>
    <v-data-table :hide-default-header="false" v-bind:headers="headers" :items="itemList" item-key="tsk_id" :search="search" v-model="taskSelected"
        :show-select="false" items-per-page-text="Lignes par page" :items-per-page-options="pagination.rowsPerItem" class="mt-0">
        <template v-slot:top>
            <v-row class="rounded-lg ma-0 pa-0" v-if="(searchBar == undefined || searchBar) || (addItemButton == undefined || addItemButton)">
                <v-col cols="6" md="6" class="pa-1">
                <v-text-field v-if="(searchBar == undefined || searchBar)" append-icon="mdi-magnify" label="Recherche" outlined dense clearable single-line
                            hide-details v-model="search" class="pa-2 rounded-xl">
                </v-text-field>
                </v-col>
                <v-col cols="6" md="6" class="text-right pa-1">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" color="success" rounded outlined :loading="loading.getXls" class="ma-2" @click="downloadTasks()">
                                <v-icon dark>mdi-file-excel</v-icon>
                                <span class="hidden-sm-and-down">Telecharger les interventions</span>
                                <span class="hidden-md-and-up mr-1 mr-1">Telecharger</span>
                            </v-btn>
                        </template>
                        <span>Telecharger xlsx</span>
                    </v-tooltip>
                    <router-link :to="(inSlider == undefined || inSlider ) ? {
                            name: $route.matched[1].name + '-slide-site-edit', params: { ent_id: $route.params.ent_id, sit_id: sitId, tsk_uid:0 }
                        } : '/tasks/' + 0 ">
                        <v-btn v-if="(addItemButton == undefined || addItemButton) && sitId" small rounded color="primary">Ajouter une action</v-btn>
                    </router-link>
                </v-col>
            </v-row>
        </template>
        <template v-slot:[`item.tty_name`]="{ item }">
            <v-chip class="ma-1" color="secondary">{{ item.tty_name }}</v-chip><v-icon>mdi-arrow-right</v-icon><br />
            <!-- <v-chip class="ma-1" small label outlined color="secondary">{{ item.tsk_uid.substring(0, 8) }}</v-chip>-->
        </template>
        <template v-slot:[`item.ent_name`]="{ item }">
            <v-chip class="ma-1 chip-list" outlined color="secondary"><v-icon>mdi-face-agent</v-icon>&nbsp;{{ item.ent_name }}</v-chip>
            <v-chip class="ma-1 chip-list" outlined color="secondary"><v-icon>mdi-town-hall</v-icon>&nbsp;{{ item.sit_name }}</v-chip><br />
            <v-chip class="ma-1 chip-list hidden-sm-and-down text-truncate" outlined color="secondary">
                <v-icon>mdi-map</v-icon>&nbsp;{{ item.sit_address + ' / ' + item.sit_town }}
            </v-chip>
            <!-- Mobile display -->
            <v-chip class="hidden-md-and-up ma-1 chip-list text-truncate" outlined color="secondary" style="max-width: 200px;">
                &nbsp;{{ item.sit_address + ' / ' + item.sit_town }}
            </v-chip>
        </template>
        <template v-slot:[`item.tsk_date_plan`]="{ item }">
            <v-chip class="ma-1 chip-list" outlined color="secondary"><v-icon>mdi-clipboard-text-clock-outline</v-icon>&nbsp;{{ $date(item.tsk_date_plan).format('DD/MM/YYYY') }}</v-chip><br />
            <v-chip v-if="item.tsk_time_plan" class="ma-1 chip-list" outlined color="secondary"><v-icon>mdi-clock-outline</v-icon>&nbsp;{{ (item.tsk_time_plan) ? item.tsk_time_plan.substring(0, 5) : '' }}</v-chip>
        </template>
        <template v-slot:[`item.tsk_typ_id`]="{ item }">
            <v-chip small label :color="typeListById[item.tsk_typ_id].typ_color">
                {{ typeListById[item.tsk_typ_id].typ_name }}
            </v-chip>
        </template>
        <template v-slot:[`item.sta_label`]="{ item }">
            <v-chip class="ma-2" outlined :color="stateListById[item.tsk_sta_id].sta_color">
                {{ stateListById[item.tsk_sta_id].sta_label }}
            </v-chip>
        </template>
        <template v-slot:[`item.tsk_id`]="{ item }">
            <v-icon v-if="item.tsk_doc_id" left color='primary' title="Télécharger bon de passage" @click="downloadVouch(item.tsk_doc_id)">
                mdi-file-download
            </v-icon>
            <router-link v-if="item.tsk_sta_id !=='TSK07'" :to="(inSlider == undefined || inSlider ) ? {
                name: $route.matched[1].name + '-slide-task', params: { ent_id: $route.params.ent_id, sit_id: item.tsk_sit_id, tsk_uid: item.tsk_uid }
            } : '/tasks/' + item.tsk_uid">
            <v-btn v-if="$root.$permissions.Task.show" rounded color="orange" elevation="0" dark>
                <v-icon left  v-if="$root.$permissions.Task.show" >{{item.sit_id}} mdi-pencil</v-icon> Fiche
            </v-btn>
            </router-link>
            <v-btn v-if="$root.$permissions.Task.show && (item.tsk_sta_id =='TSK07')" @click="restaureTask(item)" rounded color="grey" elevation="0" dark>
                <v-icon left  v-if="$root.$permissions.Task.show" >{{item.sit_id}} mdi-delete-restore</v-icon> Restaurer
            </v-btn>
        </template>
        <template slot="pageText" slot-scope="{ pageStart, pageStop }">
        De {{ pageStart }} à {{ pageStop }}
        </template>
    </v-data-table>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useTaskStore } from '@/stores/task'
import { useTypeStore } from '@/stores/type'
import { useStateStore } from '@/stores/state'
import { useFilterStore } from '@/stores/filter'
import { useDocumentStore } from '@/stores/document'

import { filterUpdateService } from '@/rxjsServices'

export default {
    name: 'site-list',
    props: [ 'searchBar', 'addItemButton', 'inSlider', 'sit_ent_id' ],
    components: { },
    computed: {
        ...mapState(useTaskStore, {itemList: 'itemList' }),
        ...mapState(useTypeStore, {typeListByGroup: 'itemListByGroup', typeListById: 'itemListById' }),
        ...mapState(useStateStore, {stateListByGroup: 'itemListByGroup', stateListById: 'itemListById' }),
        ...mapState(useFilterStore, {filterItem: 'item', getUrlFiter: 'getUrlFiter' }),
        sitId(){
            if( this.$route?.params?.sit_id ){
                return this.$route.params.sit_id
            } else {
                return 0
            }
        },
    },
    data: function () {
        return {
            loading: { getgetTaskItems: false, getXls: false },
            validFrom: true,
            massEditDialog: false,
            fieldStyle: { outlined: true, dense: true },
            taskSelected : [],
            search: '',
            pagination: { totalItems: 0, rowsPerPage: 10, rowsPerItem: [15,25,35,{text:'Tout',value:-1}] },
            headers: [
                { text: 'Client / site',  align: 'left', value: 'ent_name'},    
                { text: 'Action',            align: 'left', value: 'tty_name'},
                { text: 'Date',              align: 'left', value: 'tsk_date_plan'},
                { text: 'Etat',              align: 'left', value: 'sta_label'},
                { text: '-',                 align: 'right', value: 'tsk_id'},
            ],
        }
    },
    //beforeMount: function(){ },
    created(){
        // modification au niveau du filtre
        this.subscribeFilter = filterUpdateService.getFilter().subscribe(message => {
            if (message) {
                this.fetchList()
            }
        })
    },
    async mounted(){
        this.fetchList()
    },
    methods: {
        ...mapActions(useTaskStore, { fetchItems: 'fetchItems', downloadItems: 'downloadItems', restoreItem: 'restoreItem' }),
        ...mapActions(useDocumentStore, { downloadDocument: 'downloadItem' }),
        async fetchList(){
            let withcounttask = (this.getUrlFiter.length > 1) ? ',withcounttask' : ''
            withcounttask += (this.sit_ent_id) ? '&sit_ent_id=' + this.sit_ent_id : ''
            await this.fetchItems( '?extended=withtasktype,withentity,withstate' + withcounttask + this.getUrlFiter + '&per_page=false' )
        },
        async downloadTasks(){
            let option = ''
            if( this.$route.params.ent_id && parseInt( this.$route.params.ent_id ) > 0 ){
                option += '&ent_id=' + this.$route.params.ent_id
            }
            (this.sit_ent_id) ? '&sit_ent_id=' + this.sit_ent_id : ''
            const report = await this.downloadItems('?extended=withcounttasktype,withtasktype,withentity&per_page=false' + option + this.getUrlFiter)
            this.$download(report, "liste.xlsx", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
        },
        async downloadVouch(docId){
            const report = await this.downloadDocument( docId )
            this.$download(report.blob, 'bdp', report.contentType)
        },
        restaureTask(item){
            item.tsk_sta_id = 'TSK05'
            this.restoreItem(item)
            if(this.inSlider == undefined || this.inSlider ) {
                this.$router.push({ name: this.$route.matched[1].name + '-slide-task', params: { ent_id: this.$route.params.ent_id, sit_id: item.tsk_sit_id, tsk_uid: item.tsk_uid } })
            }
            else {
                this.$router.push('/tasks/' + item.tsk_uid)
            }
        }
    },
    beforeDestroy() {
      this.subscribeFilter.unsubscribe()
    },
}
</script>