<template>
    <v-container fluid class="pa-0">
    <!-- Formulaire -->
    <v-card fluid class="ma-2 pa-3 elevation-0 rounded-xxl micorise-v-card">
        <v-card-title >
            Dépôts
            <v-spacer></v-spacer>
            <v-chip outlined color="secondary">
                <v-icon left>mdi-information-outline</v-icon>Modification: {{ $date(item.updatedAt).format('DD MMMM YYYY') }}
            </v-chip>
        </v-card-title>
        <v-form ref="form" v-model="validForm" lazy-validation>
        <v-row no-gutters>
            <v-col cols="12" md="8" class="pr-2">
                <v-text-field :rules="nameRules" outlined dense class="rounded-lg" label="Nom" v-model="item.str_name" ></v-text-field>
            </v-col>
            <v-col cols="12" md="8" class="pr-2">
                <AutocompleteMulti v-if="!loading.fetchData" :disabled="true"
                :title="'Agence'"
                :listObject.sync="item.str_agc_id" :listParent.sync="listAgencies" 
                ressource="agencies"
                :fields="{ 
                    index: 'agc_id', text:'agc_name', autofocus: true, outlined: true, dense: true,
                    search:'agc_name', multiSelect:false, prefetch: true, resultlimit: 'false', params:''
                }"></AutocompleteMulti>
            </v-col>
            <v-col cols="11" md="8" class="pr-2">
                <v-autocomplete
                    v-model.trim="searchAddress"
                    label="Rechercher adresse"
                    :items="addressTab"
                    item-value="id" item-text="label"
                    :search-input.sync="search"
                    @change="selectAdress" @focus="searchBoxClosed = false" @blur="searchBoxClosed = true"
                    prepend-inner-icon="mdi-magnify" filled outlined dense clearable no-filter
                    class="inline rounded-lg shrink expanding-search"
                    background-color="white">
                </v-autocomplete>
            </v-col>
            <v-col cols="1" md="1" class="pr-2">
                <v-btn outlined color="secondary" class="mb-2" @click="modalGeolocate = !modalGeolocate" title="Localiser manuellement">
                    <v-icon>mdi-map-marker-radius-outline</v-icon>
                </v-btn>
            </v-col>
            <v-col cols="12" md="4" class="pr-2">
                <v-text-field :rules="nameRules" outlined dense class="rounded-lg" label="Adresse complément" v-model="item.str_address"></v-text-field>
            </v-col>
            <v-col cols="12" md="3" class="pr-2">
                <v-text-field :rules="nameRules" outlined dense class="rounded-lg" label="Ville" v-model="item.str_town"></v-text-field>
            </v-col>
            <v-col cols="12" md="1" class="pr-2">
                <v-text-field outlined dense class="rounded-lg" label="Code postal" v-model="item.str_zip"></v-text-field>
            </v-col>
            <v-col cols="12" md="8" class="pr-2">
                <v-textarea outlined dense class="rounded-lg" label="Description" v-model="item.str_description"></v-textarea>
            </v-col>
            <v-col cols="12" md="4" class="pr-2">
                
            </v-col>
        </v-row>
        </v-form>
        <v-card-actions>
            <v-btn class="rounded-xl" large color="red" v-if="item.uid" @click="deleteItem(item)"><v-icon title="Supprimer">mdi-delete</v-icon></v-btn>
            <v-spacer></v-spacer>
            <v-btn class="rounded-xl" large color="success" :loading="loading.fetchData" @click.native="saveItem()" :disabled="(loading.fetchData || !validForm)">Enregistrer</v-btn>
            <v-btn class="rounded-xl" large color="primmary" href="/#/stores/">Retour</v-btn>
        </v-card-actions>
    </v-card>
    <v-dialog v-model="modalGeolocate" hide-overlay max-width="900" content-class="rounded-xxl micorise-v-card">
        <MapGeolocate v-if="modalGeolocate" :latLng="{lat: item.ent_lat, lng: item.ent_lng}" :switchGeolocate="switchGeolocate" />
    </v-dialog>
    </v-container>
</template>
    
<script>
import AutocompleteMulti from '@/components/ui/AutocompleteMulti'
import MapGeolocate from '@/components/map/MapGeolocatePopup'

import { mapWritableState, mapActions } from 'pinia'
import { useStoreStore } from '@/stores/store'

export default {
    name: 'stores-edit',
    components: { AutocompleteMulti, MapGeolocate },
    computed: {
        ...mapWritableState(useStoreStore, {item: 'item' }),
    },
    watch: {
        search (val) {
            this.searchWord = val
            if( this.timeOutHandle == 'off' && val && val.length >= 3 ){
                this.timeOutHandle = 'on'
                clearTimeout(this.searchTempo)
                this.searchTempo = setTimeout( () => {
                    this.loadAddress(this.searchWord)
                    this.timeOutHandle = 'off'
                }, 1500)
            }
        },
    },
    data: function () {
        return {
            loading: { fetchData: true },
            listEntities: [], listAgencies: [],
            search: null, searchAddress: null, searchBoxClosed: true, addressTab: [], timeOutHandle: 'off',
            modalGeolocate: false,
            validForm: true,
            nameRules: [ v => !!v || 'Le champ doit être renseigné', v => (v && v.length <= 100) || 'Doit être inférieur à 100 charactères', ],
            emailRules: [ v => !!v || 'E-mail requis', v => /.+@.+\..+/.test(v) || 'E-mail doit être valide', ],
        }
    },
    async beforeMount () {
        await this.fetchItem( this.$route.params.str_id, '?ent_sta_id=GEN01' )
        this.loading.fetchData = false
    },
    methods: {
        ...mapActions(useStoreStore, {fetchItem: 'fetchItem', updateItem: 'updateItem', storeItem: 'storeItem'}),
        async saveItem () {
            await this.$refs.form.validate()
            if( this.validForm ){
                // Enregistrement du formulaire
                this.loading.fetchData = true
                if( parseInt( this.$route.params.str_id ) > 0 ){
                    this.updateItem()
                } else {
                    this.storeItem()
                }
                this.$router.push({ path: '/stores' })
            }
        },
        loadAddress (val) {
            if( val.length > 1 ){
                this.$http.customRequest( {
                    headers: '',
                    baseURL: '',
                    method: 'get',
                    url: "https://api-adresse.data.gouv.fr/search/?q=" + val + "&limit=6"
                })
                .then( (response) => {
                    let data = []
                    let address, key
                    for (key = 0; (address = response.data.features[key]) !== undefined; key++) {
                        data[key] = { label: address.properties.label, value: address.properties.id, id:key, item: address }
                    }
                    this.addressTab = data
                })
            } else {
                return false
            }
        },
        selectAdress(){
            if( this.searchAddress !== null && this.searchAddress !== undefined ){
                this.item.str_address = this.addressTab[this.searchAddress]?.item?.properties?.name
                this.item.str_town = this.addressTab[this.searchAddress]?.item?.properties?.city
                this.item.str_zip = this.addressTab[this.searchAddress]?.item?.properties?.postcode
                this.item.str_lat = this.addressTab[this.searchAddress]?.item?.geometry?.coordinates[1]
                this.item.str_lng = this.addressTab[this.searchAddress]?.item?.geometry?.coordinates[0]
            }
        },
        switchGeolocate(latLng){
            if( latLng && latLng.lat ){
                this.item.str_lat     = latLng.lat
                this.item.str_lng     = latLng.lng
                if( !this.item.str_address  ){
                    this.item.str_address = latLng.address
                    this.item.str_town    = latLng.town
                    this.item.str_zip     = latLng.zip
                    this.item.str_lat     = latLng.lat
                    this.item.str_lng     = latLng.lng
                }
            }
            this.modalGeolocate = false
        },
    }
}
</script>