<template>
<v-container>
    <v-row no-gutters >
        <v-col cols="12" md="12">
            <img width="110" :src="logo[planId]" style="cursor:pointer;" class="ml-1" />
            <v-alert border="left" color="rgb(61, 140, 120)" dark class="float-right">
                <span style="color:white;" class="subtitle-1"><i>{{ terms }}</i></span>
            </v-alert>
        </v-col>
        <v-col cols="12" md="12">
            <div v-html="planItem.typ_customfields.typ_description"></div>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
export default {
    name: 'sign-plan-component',
    props: ['planId', 'planItem'],
    components: { },
    computed: {
        terms(){
            if( this.planId == 'SUB01' ){
                return 'Gratuit pendant 1 an (Annulez à tout moment)'
            } else {
                return 'Premier mois gratuit, puis ' + Math.round( parseFloat(this.planItem.typ_customfields.typ_value) + (parseFloat(this.planItem.typ_customfields.typ_value) * 0.2), 2) + '€ / mois (Annulez à tout moment)'
            }
        }
    },
    data () {
        return {
            logo: {
                'SUB02': require('@/assets/logo_abo1.svg'),
                'SUB03': require('@/assets/logo_sub_arbor.svg'),
                'SUB04': require('@/assets/logo_sub_silva.svg'),
                'SUB01': require('@/assets/logo_sub_seminis.svg'),
            },
        }
    },
    mounted:function (){

    }
}
</script>