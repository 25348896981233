<template>
<v-container fluid class="pa-0">
<!-- Formulaire -->
        <v-card fluid class="ma-2 pa-3 elevation-0 rounded-xxl micorise-v-card">
        <v-layout row wrap class="layout-flex-2 mt-4">
            <v-flex xs12 class="pl-6 pr-6">
                <v-text-field outlined dense class="rounded-lg" label="Dénomination sociale"  v-model="item.cpy_name"></v-text-field>
            </v-flex>
            <!-- Adresse ------------------->
            <v-flex xs12 class="pl-6 pr-6">
                <v-autocomplete
                    v-model.trim="searchAddress"
                    label="Rechercher adresse"
                    :items="addressTab"
                    item-value="id" item-text="label"
                    :search-input.sync="search"
                    @change="selectAdress" @focus="searchBoxClosed = false" @blur="searchBoxClosed = true"
                    append-icon="mdi-magnify" filled outlined dense clearable no-filter
                    class="inline rounded-lg shrink expanding-search"
                    background-color="white">
                </v-autocomplete>
            </v-flex>
            <!-- right side -->
            <v-flex xs5 class="pl-6 pr-6">
                <v-text-field ref="address" outlined dense class="rounded-lg" label="Adresse / complément" v-model="item.cpy_address"></v-text-field>
            </v-flex>
            <v-flex xs4 class="pl-6 pr-6">
                <v-text-field outlined dense class="rounded-lg" label="Ville" v-model="item.cpy_town"></v-text-field>
            </v-flex>
            <v-flex xs3 class="pl-6 pr-6">
                <v-text-field outlined dense class="rounded-lg" label="Code postal" v-model="item.cpy_zip"></v-text-field>
            </v-flex>
            <v-flex xs12 class="pl-6 pr-6 pb-6">
                <v-divider></v-divider>
            </v-flex>
            <v-flex xs6 class="pl-6 pr-6">
                <v-text-field outlined dense class="rounded-lg" label="Adresse de facturation (si différente)"  v-model="item.cpy_customfields.cpy_address"></v-text-field>
            </v-flex>
            <v-flex xs6 class="pl-6 pr-6">
                <v-text-field outlined dense class="rounded-lg" label="Complément adresse"  v-model="item.cpy_customfields.cpy_address_comp"></v-text-field>
            </v-flex>
            <v-flex xs6 class="pl-6 pr-6">
                <v-text-field outlined dense class="rounded-lg" label="Ville" v-model="item.cpy_customfields.cpy_town"></v-text-field>
            </v-flex>
            <v-flex xs6 class="pl-6 pr-6">
                <v-text-field outlined dense class="rounded-lg" label="Code postal" v-model="item.cpy_customfields.cpy_zip"></v-text-field>
            </v-flex>


            <v-flex xs12 class="pl-6 pr-6">
                <v-text-field outlined dense class="rounded-lg" label="Adresse e-mail" v-model="item.cpy_mail"></v-text-field>
            </v-flex>
            <v-flex xs6 class="pl-6 pr-6" ref="mobile">
                <v-text-field outlined dense class="rounded-lg" label="Tel. portable" v-model="item.cpy_phone"></v-text-field>
            </v-flex>
            <v-flex xs6 class="pl-6 pr-6">
                <v-text-field outlined dense class="rounded-lg" label="Tel. Fixe" v-model="item.cpy_customfields.cpy_phone"></v-text-field>
            </v-flex>
            <v-flex xs6 class="pl-6 pr-6">
                <v-text-field outlined dense class="rounded-lg" label="TVA Intracommunautaire"  v-model="item.cpy_customfields.cpy_tvaintra"></v-text-field>
            </v-flex>
            <v-flex xs6 class="pl-6 pr-6">
                <v-text-field outlined dense class="rounded-lg" label="Siret"  v-model="item.cpy_siret"></v-text-field>
            </v-flex>
            <!-- -------------------------- -->
            <v-flex xs6 class="pl-6 pr-6" v-if="$root.$permissions?.Company?.delete">
                <v-select outlined dense class="rounded-lg"
                    item-text="sta_label" item-value="sta_id"
                    :items="stateListByGroup['GEN']" v-model="item.cpy_sta_id" label="Statut" single-line >
                </v-select>
            </v-flex>
        </v-layout>
    </v-card>
    <v-toolbar extended flat color="transparent" class="elevation-0" >
        <v-btn v-if="item.cpy_active" :disabled="!item.cpy_id" rounded color="error" @click.native="deleteItem()"><v-icon left dark>mdi-close</v-icon>Supprimer</v-btn>
        <v-btn v-if="!item.cpy_active" :disabled="!item.cpy_id" rounded color="blue" @click.native="item.cpy_active = 1; saveItem();"><v-icon left dark>mdi-close</v-icon>Activer</v-btn>
        <v-spacer></v-spacer>
        <v-btn rounded text href="/#/companies/"><v-icon left dark>mdi-close</v-icon>Annuler</v-btn>
        <v-btn rounded color="success" class="ml-3" :loading="loading.saveProgress" @click.native="saveItem()" :disabled="(loading.saveProgress || !item.cpy_active)">
            <v-icon left dark>mdi-content-save-outline</v-icon>Enregistrer
        </v-btn>
    </v-toolbar>
</v-container>
</template>

<script>
import { mapState, mapWritableState, mapActions } from 'pinia'
import { useCompanyStore } from '@/stores/company'

import { useTypeStore } from '@/stores/type'
import { useStateStore } from '@/stores/state'

export default {
    name: 'companies-edit',
    props: [ ],
    components: { },
    data: function () {
        return {
            loading: { fetchData: false, saveProgress: false },
            search: null, searchAddress: null, searchBoxClosed: true, addressTab: [], timeOutHandle: 'off',
        }
    },
    watch: {
        search (val) {
            this.searchWord = val
            if( this.timeOutHandle == 'off' && val && val.length >= 3 ){
                this.timeOutHandle = 'on'
                clearTimeout(this.searchTempo)
                this.searchTempo = setTimeout( () => {
                    this.loadAddress(this.searchWord)
                    this.timeOutHandle = 'off'
                }, 1500)
            }
        },
    },
    computed: {
        ...mapWritableState(useCompanyStore, {item: 'item' }),
        ...mapState(useTypeStore, {typeListByGroup: 'itemListByGroup', typeListById: 'itemListById' }),
        ...mapState(useStateStore, {stateListByGroup: 'itemListByGroup', stateListById: 'itemListById' }),
    },
    async mounted (){
        await this.fetchItem( this.$route.params.cpy_id, '?ent_sta_id=GEN01' )
        this.loading.fetchData = false
    },
    methods: {
        ...mapActions(useCompanyStore, {fetchItem: 'fetchItem', updateItem: 'updateItem', storeItem: 'storeItem', destroyItem: 'destroyItem'}),
        async saveItem () {
            // Enregistrement du formulaire
            this.loading.fetchData = true
            if( parseInt( this.$route.params.cpy_id ) > 0 ){
                this.updateItem()
            } else {
                this.storeItem()
            }
            this.$router.push({ path: '/companies' })
        },
        async deleteItem(){
            this.$root.$confirm('Supprimer', 'Confirmer suppression ?', { color: 'red', width: 400 }).then((confirm) => {
                if( confirm ){
                    this.destroyItem().then( () => {
                        this.$router.push({ path: '/companies' })
                    })
                }
            })
        },
        loadAddress (val) {
            if( val.length > 1 ){
                this.$http.customRequest( {
                    headers: '',
                    baseURL: '',
                    method: 'get',
                    url: "https://api-adresse.data.gouv.fr/search/?q=" + val + "&limit=6"
                })
                .then( (response) => {
                    let data = []
                    let address, key
                    for (key = 0; (address = response.data.features[key]) !== undefined; key++) {
                        data[key] = { label: address.properties.label, value: address.properties.id, id:key, item: address }
                    }
                    this.addressTab = data
                })
            } else {
                return false
            }
        },
        selectAdress(){
            if( this.searchAddress !== null && this.searchAddress !== undefined ){
                this.item.cpy_address = this.addressTab[this.searchAddress]?.item?.properties?.name
                this.item.cpy_town = this.addressTab[this.searchAddress]?.item?.properties?.city
                this.item.cpy_zip = this.addressTab[this.searchAddress]?.item?.properties?.postcode
                this.item.cpy_lat = this.addressTab[this.searchAddress]?.item?.geometry?.coordinates[1]
                this.item.cpy_lng = this.addressTab[this.searchAddress]?.item?.geometry?.coordinates[0]
            }
            this.searchAddress = ''
            this.addressTab.splice(0)
            this.$refs.mobile.focus()
        },
    }
}
</script>