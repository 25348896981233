<template>
<v-card elevation="0" class="rounded-lr-xxl">
    <v-card-text elevation="0" class="mt-2">
    <v-layout row wrap>
        <v-flex xs12 class="pa-2">
            <DatePicker v-if="!loading.fetchData" v-model="item.wti_date_start" label="Réalisé le" :objectForm="item" fieldName='wti_date_start'></DatePicker>
        </v-flex>

        <v-flex xs6 class="pa-2">
            <AutocompleteMulti 
                        :title="'Agent'" :ressource="'users'"
                        :listObject.sync="item.wti_usr_id" :listParent="userList" 
                        :fields="{
                            index: 'id', text:'middleName', autofocus: true,
                            search:'middleName', preprendIcon:'mdi-account-group', multiSelect:false, prefetch: false, resultlimit:'25', params:'' 
                    }"></AutocompleteMulti>
        </v-flex>
        <v-flex xs6 class="pa-2">
        <AutocompleteMulti 
                :title="'Materiel'" :ressource="'equipments'"
                :listObject.sync="item.wti_equ_id" :listParent="equipmentList" 
                :fields="{ 
                    index: 'equ_id', text:'equ_name', autofocus: true, deletablechips: true,
                    search:'equ_name', preprendIcon:'mdi-tools', multiSelect:false, prefetch: true, params:'' 
            }"></AutocompleteMulti>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-select v-show="(!item.wti_equ_id)" prepend-icon="mdi-timetable" :items="this.typeListByGroup['WTI']" item-text="typ_name" item-value="typ_id" :outlined="true"
                v-model="item.wti_typ_id" label="Statut" single-line >   
            </v-select>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-text-field ref="wtiDuration" outlined dense class="rounded-lg" prepend-icon="mdi-clock-time-two-outline" 
                :label="(item.wti_equ_id) ? 'Temps materiel' : 'Temps de travail'" 
                v-model="item.wti_duration" type="time" >
            </v-text-field>
        </v-flex>
        <v-flex xs12 class="pa-2">
            <v-alert border="left" colored-border color="deep-purple accent-4" elevation="2" >
                Si un matériel est selectionné, le temps compte pour du temps matériel
                <v-chip label outlined v-show="item.wti_created_at" class="d-flex flex-column">
                Création : 
                {{ (item.wti_created_at) ? $date(item.wti_created_at).format('DD/MM/YYYY') : '' }}
            </v-chip>
            </v-alert>
        </v-flex>
    </v-layout>
    </v-card-text>
    <v-divider light></v-divider>
    <v-card-actions>
        <v-menu v-if="(!item.wti_validated) && (item.wti_id > 0)" v-model="optionMenu" offset-y transition="slide-x-transition" bottom right >
            <template v-slot:activator="{ on }">
            <v-btn v-on="on" outlined class="ml-0 pa-0" style="min-width: 5px;" color="grey"><v-icon>mdi-dots-vertical</v-icon></v-btn>
            </template>
            <v-btn class="ma-0" color="error" @click.native="deleteWorktime()" :disabled="saveProgress">
                <v-icon left>mdi-trash-can</v-icon>Supprimer
            </v-btn>
        </v-menu>
        <v-spacer></v-spacer>
        <v-btn rounded v-if="(!item.wti_validated )"
            color="success" 
            :loading="saveProgress" @click.native="saveWorktime()" :disabled="!validForm">
            Enregistrer
        </v-btn>
        <v-btn rounded outlined color="grey" @click="switchModal('modalWorktime', 0)">
            Fermer
        </v-btn>
    </v-card-actions>
</v-card>
</template>

<script>
import DatePicker from '@/components/ui/DatePicker'
import AutocompleteMulti from '@/components/ui/AutocompleteMulti'

import { mapState, mapWritableState, mapActions } from 'pinia'
import { useWorkTimeStore } from '@/stores/worktime'
import { useTypeStore } from '@/stores/type'
import { useUserStore } from '@/stores/user'
import { useEquipmentStore } from '@/stores/equipment'

export default {
    name: 'worktime-edit',
    components: { DatePicker, AutocompleteMulti },
    props: ['switchModal', 'wti_id', 'tsk_id'],
    //mixins: [ wtiObj ],
    computed: {
        ...mapWritableState(useWorkTimeStore, {item: 'item' }),
        ...mapState(useTypeStore, {typeListByGroup: 'itemListByGroup', typeListById: 'itemListById' }),
        ...mapState(useUserStore, {userList: 'itemList', user: 'me' }),
        ...mapState(useEquipmentStore, {equipmentList: 'itemList' }),
        validForm(){
            return (this.item.wti_usr_id && this.item.wti_duration !== '00:00')
        },
    },
    data: function () {
        return {
            loading : { fetchData: true },
            dateMenu: { date_start:false, date_end:false },
            saveProgress: false,         // Enregistrement en cours...
            dateEve: { start: false, end: false },
            optionMenu: false,
            autoComplete: { user : [], },
            autoCompleteSource:{ user : [], },
            assocItem: true,
        }
    },
    //beforeMount: function(){ },
    async mounted (){
        if(!this.wti_id){
            this.item.wti_id         = 0
            this.item.wti_date_start = this.$date().format('YYYY-MM-DD')
            this.item.wti_typ_id     = 'WTI01'
            this.item.wti_tsk_id     = this.tsk_id
            this.item.wti_usr_id     = this.user.id
            this.item.wti_equ_id     = null
            this.item.wti_duration   = 0
            setTimeout( () => { this.$refs['wtiDuration'].focus() }, 500)
        } else {
            await this.fetchItem(this.wti_id)
        }
        this.loading.fetchData = false
        this.assocItem = false
    },
    methods: {
        ...mapActions(useWorkTimeStore, { fetchItem: 'fetchItem', updateItem: 'updateItem', storeItem: 'storeItem', destroyItem:'destroyItem' }),
        getWorktime(){
            return new Promise( (resolve, reject) => {
                this.loading.getSheet = true
                this.$http.get( '/worktimes/' + this.item.wti_id + '/?include=user,action').then( (response) => {
                    this.item = response.data.data
                    this.loading.getSheet = false
                    this.autoComplete.user = this.item.wti_usr_id
                    this.autoCompleteSource.user.push(this.item.user.data)
                    this.assocItem = false
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        saveWorktime(){
            //this.item.wti_usr_id = 3
            if( this.item.wti_id > 0 ){
                this.$http.put( '/worktimes/' + this.item.wti_id, this.item ).then( () => {
                    this.loading.getSheet = false
                    this.switchModal('modalWorktime', 0, 1)
                    this.$http.put( '/tasks/' + this.item.wti_tsk_id + '/computeduration/?tsk_id=' + this.item.wti_tsk_id )
                })
            } else {
                this.$http.post( '/worktimes/', this.item ).then( () => {
                    this.loading.getSheet = false
                    this.switchModal('modalWorktime', 0, 1)
                    this.$http.put( '/tasks/' + this.item.wti_tsk_id + '/computeduration/?tsk_id=' + this.item.wti_tsk_id )
                })
            }
        },
        deleteWorktime(){
            this.$root.$confirm('Supprimer', 'Confirmer la suppression de cette fiche', { color: 'red', width: 500 }).then((confirm) => {
                if( confirm ){
                    this.$http.delete( '/worktimes/' + this.item.wti_id ).then( () => {
                        this.$root.$toast({ color: 'success', top:false, bottom:true, right:false, left:true, text:'Fiche supprimée !' })
                        this.switchModal('modalWorktime', 0, 1)
                    })
                }
            })
        }
    }
}
</script>