var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',{staticClass:"mt-0",attrs:{"headers":_vm.headers,"items":_vm.itemList,"item-key":"id","search":_vm.search,"items-per-page-text":"Lignes par page","hide-default-header":"","items-per-page-options":_vm.pagination.rowsPerItem},scopedSlots:_vm._u([{key:"top",fn:function(){return [((_vm.searchBar == undefined || _vm.searchBar) || (_vm.addItemButton == undefined || _vm.addItemButton))?_c('v-row',{staticClass:"rounded-lg ma-0 pa-0"},[_c('v-col',{staticClass:"pa-1",attrs:{"cols":"6","md":"6"}},[((_vm.searchBar == undefined || _vm.searchBar))?_c('v-text-field',{staticClass:"pa-2 rounded-xl",attrs:{"append-icon":"mdi-magnify","label":"Recherche","outlined":"","dense":"","clearable":"","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"1","md":"1"}},[_c('v-btn',{attrs:{"small":"","rounded":"","color":(_vm.sitState == 'GEN01') ? 'grey' : 'blue'},on:{"click":function($event){(_vm.sitState === 'GEN01') ? _vm.sitState='GEN01,GEN02' : _vm.sitState='GEN01'; _vm.fetchList();}}},[_vm._v("Voir sites en veille")])],1),((_vm.addItemButton == undefined || _vm.addItemButton))?_c('v-col',{staticClass:"text-right pa-1",attrs:{"cols":"5","md":"5"}},[_c('router-link',{attrs:{"to":(_vm.inSlider || _vm.inSlider == undefined) ? {
                        name: _vm.$route.matched[1].name + '-slide-site-edit', params: { ent_id: _vm.$route.params.ent_id, sit_id: 0 }
                    } : 0}},[_c('v-btn',{attrs:{"small":"","rounded":"","color":"primary"}},[_vm._v("Ajouter un site")])],1)],1):_vm._e()],1):_vm._e()]},proxy:true},{key:`item.sit_name`,fn:function({ item }){return [_c('v-chip',{staticClass:"ma-2",attrs:{"color":"secondary"}},[_vm._v(_vm._s(item.sit_name))]),_c('v-icon',[_vm._v("mdi-arrow-right")])]}},{key:`item.ent_name`,fn:function({ item }){return [_c('v-chip',{staticClass:"ma-2",attrs:{"outlined":"","color":"secondary"}},[_c('v-icon',[_vm._v("mdi-face-agent")]),_vm._v(" "+_vm._s(item.ent_name))],1)]}},{key:`item.tsk_id_count`,fn:function({ item }){return [_c('v-chip',{staticClass:"ma-2",attrs:{"outlined":"","color":(item.tsk_id_count > 0) ? 'green' : 'grey',"title":(!item.sit_lat) ? 'site non géolocalisé' : 'Site(s)'}},[((!item.sit_lat))?_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" "+_vm._s((!item.sit_lat) ? 'mdi-map-marker-off-outline' : 'mdi-map-marker-multiple')+" ")]):_vm._e(),_c('v-icon',[_vm._v("mdi-traffic-cone")]),_vm._v(" "+_vm._s(item.tsk_id_count)+" ")],1)]}},{key:`item.sit_id`,fn:function({ item }){return [_c('router-link',{attrs:{"to":(_vm.inSlider == undefined || _vm.inSlider ) ? {
            name: _vm.$route.matched[1].name + '-slide-task', params: { ent_id: _vm.$route.params.ent_id, sit_id: item.sit_id, tsk_uid: 0 }
        } : '/tasks/' + 0}},[(_vm.$root.$permissions.Site.update)?_c('v-btn',{staticClass:"mr-2",attrs:{"rounded":"","color":"green","elevation":"0","dark":"","title":"Ajouter une tâche"}},[(_vm.$root.$permissions.Site.update)?_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]):_vm._e(),(_vm.$root.$permissions.Site.update)?_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-map-marker-multiple")]):_vm._e()],1):_vm._e()],1),_c('router-link',{attrs:{"to":(_vm.inSlider || _vm.inSlider == undefined) ? {
                name: _vm.$route.matched[1].name + '-slide-site-edit', params: { ent_id: _vm.$route.params.ent_id, sit_id: item.sit_id }
            } : item.sit_id}},[(_vm.$root.$permissions.Site.update)?_c('v-btn',{attrs:{"rounded":"","color":"orange","elevation":"0","dark":""}},[(_vm.$root.$permissions.Site.update)?_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(item.sit_id)+" mdi-pencil")]):_vm._e(),_vm._v(" Fiche ")],1):_vm._e()],1)]}},{key:"no-data",fn:function(){return [_c('v-alert',{attrs:{"value":( !_vm.itemList.lenght ),"color":"error","icon":"mdi-alert"}},[_vm._v(" Aucun evenement ")])]},proxy:true}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }