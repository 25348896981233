<template>
    <v-container fluid class="pa-0">
    <!-- Formulaire -->
    <v-card fluid class="ma-2 pa-3 elevation-0 rounded-xxl micorise-v-card">
        <v-card-title >
            Nous contacter
            <v-spacer></v-spacer>
            <v-chip outlined color="secondary">
                <v-icon left>mdi-information-outline</v-icon>Modification: {{ $date(item.updatedAt).format('DD MMMM YYYY') }}
            </v-chip>
        </v-card-title>
        <v-form ref="form" v-model="validForm" lazy-validation>
        <v-row no-gutters>
            <v-col cols="12" md="8" class="pr-2">
                <v-text-field :rules="nameRules" prepend-inner-icon="mdi-account" outlined dense class="rounded-lg" label="Nom" v-model="item.name" ></v-text-field>
            </v-col>
            <v-col cols="12" md="8" class="pr-2">
                <v-text-field :rules="emailRules" prepend-inner-icon="mdi-mail" outlined dense class="rounded-lg" label="Mail" v-model="item.email" ></v-text-field>
            </v-col>
            <v-col cols="12" md="8" class="pr-2">
                <v-text-field :rules="nameRules" prepend-inner-icon="mdi-message-badge-outline" outlined dense class="rounded-lg" label="Sujet" v-model="item.subject" ></v-text-field>
            </v-col>
            <v-col cols="11" md="8" class="pr-2">
                
            </v-col>
            <v-col cols="12" md="8" class="pr-2">
                <v-textarea outlined dense class="rounded-lg" label="Message" v-model="item.message"></v-textarea>
            </v-col>
            <v-col cols="12" md="4" class="pr-2">
                
            </v-col>
        </v-row>
        </v-form>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="rounded-xl" large color="success" :loading="loading.fetchData" @click.native="sendMail()" :disabled="(loading.fetchData || !validForm)">Enregistrer</v-btn>
            <v-btn class="rounded-xl" large color="primmary" href="/#/dashboard/">Retour</v-btn>
        </v-card-actions>
    </v-card>
    </v-container>
</template>
    
<script>

//import { mapWritableState } from 'pinia'
//import { useStoreStore } from '@/stores/store'

export default {
    name: 'contact-us',
    components: {  },
    computed: {
        //...mapWritableState(useStoreStore, {userItem: 'item' }),
    },
    data: function () {
        return {
            loading: { fetchData: true },
            item: { name: '', email: '', subject: '', message: '' },
            validForm: true,
            nameRules: [ v => !!v || 'Le champ doit être renseigné', v => (v && v.length <= 100) || 'Doit être inférieur à 100 charactères', ],
            emailRules: [ v => !!v || 'E-mail requis', v => /.+@.+\..+/.test(v) || 'E-mail doit être valide', ],
        }
    },
    async beforeMount () {
        this.item.name   = this.$root.$userInfo?.firstName
        this.item.email = this.$root.$userInfo?.email

        this.loading.fetchData = false
    },
    methods: {
        async sendMail () {
            await this.$refs.form.validate()
            if( this.validForm ){
                // Enregistrement du formulaire
                this.loading.fetchData = true
                this.$http.post( '/contactus/', this.item ).then( () => {
                    this.$root.$toast({ color: 'success', top:false, bottom:true, right:false, left:true, text:'Message envoyé !' })
                    this.loading.fetchData = false
                    this.$router.push({ path: '/dashboard' })
                })
            }
        },
    }
}
</script>