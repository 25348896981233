<template>
    <v-card-text style="min-height:440px;">
        <VueApexCharts v-if="loading.fetchData" width="100%"  type="bar" :options="chart1Options" :series="series1"></VueApexCharts>
        <v-alert v-else color="info" class="mt-2" icon="mdi-alert">Aucune donnée</v-alert>
    </v-card-text>  
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import apexLocale  from '@/plugins/apexchart.js'

import { mapState, mapActions } from 'pinia'
import { useWorkTimeStore } from '@/stores/worktime'
import { useTaskStore } from '@/stores/task'

export default {
    name: 'customer-graph',
    components: { VueApexCharts },
    props: ['ent_id'],
    computed: {
        ...mapState(useWorkTimeStore, {wortimeList: 'itemList' }),
        ...mapState(useTaskStore, {taskList: 'itemList' }),
    },
    data: function () {
        return {
            loading: { fetchData: false },
            chart1Options: {
                chart: { 
                    toolbar: { export: { csv: { filename: 'Heure par mois', columnDelimiter: ';' } } },
                    locales: apexLocale.locales, defaultLocale: "fr" 
                },
                yaxis: [ { title: { text: '', }, }, ],
                dataLabels: { enabled: true, offsetX: -6, style: { fontSize: '10px', colors: ['#000'] } },
                stroke: { show: true, colors: ['#fff'] },
                xaxis: { categories: [], }, //type: 'datetime',
            },
            series1: [ ], 
        }
    },
    async mounted () {
        let currentYear = new Date().getFullYear()
        //await this.fetchWorktimeItems('?ent_id=' + this.ent_id + '&per_page=false&extended=groupbymonth&min_wti_date_start=' + currentYear + '-01-01&max_wti_date_start=' + currentYear + '-12-31')
        await this.fetchTaskItems('?sit_ent_id=' + this.ent_id + '&per_page=false&extended=groupbymonth,withworktime&min_tsk_date_plan=' + currentYear + '-01-01&max_tsk_date_plan=' + currentYear + '-12-31')
        this.computeSerieOne()
    },
    methods: {
        ...mapActions(useWorkTimeStore, {fetchWorktimeItems: 'fetchItems'}),
        ...mapActions(useTaskStore, { fetchTaskItems: 'fetchItems', downloadItems: 'downloadItems' }),
        computeSerieOne(){
            return new Promise( (resolve) => {
                var chart1Options = JSON.parse( JSON.stringify( this.chart1Options ) )
                chart1Options.xaxis.categories = Array.from({length: 12}, (item, i) => {
                    return new Date(0, i).toLocaleString('fr-FR', {month: 'long'})
                })
                let series1     = [
                    { name: 'Temps prévu', type: 'column', data: Array.from({length: 12}, () => { return 0 }) },
                    { name: 'Temps passé', type: 'column', data: Array.from({length: 12}, () => { return 0 }) },
                ]
                //this.wortimeList.forEach(element => {
                this.taskList.forEach(element => {
                    let month = new Date(element.tsk_date_plan).getMonth()
                    series1[0].data[month] = Math.round( (element.tsk_duration_plan_sum / 60) / 60 ) 
                    series1[1].data[month] = Math.round( (element.wti_duration_sum / 60) / 60 ) 
                    //series1[1].data[month] = Math.round( (element.tsk_duration_sum / 60) / 60 ) 
                    //chart1Options.xaxis.categories[month] = new Date(element.wti_date_start).toLocaleString('default', { month: 'long' } )
                })
                this.series1          = series1
                this.chart1Options    = chart1Options
                this.loading.fetchData = true
                resolve('resolved')
            })
        },
    },
}
</script>