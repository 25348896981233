<template>
<!-- Formulaire -->
        <v-card fluid class="ma-2 pa-5 elevation-2 rounded-lg">
            <v-row>
                <v-col cols="12" md="6" class="pa-2">
                <v-alert border="left" colored-border color="deep-purple accent-4" elevation="2" >
Explication du parrainage :<br />
Le parrainage est une formule pour vous permettre de diminuer le coût de votre
abonnement. Le principe est de parrainer un confrère. <br />
Lorsque ce confrère commence son abonnement votre abonnement diminue de 10 euros. Cette réduction est valable
indéfiniment. Suivant votre abonnement, vous pouvez rapidement arriver à 0 euros. Si vous
continuez à parrainer des confrères, à chaque parrainage, vous créditez une cagnotte de 10
euros. A vous de dépenser cette cagnotte avec l’une de nos entreprises partenaire grâce à
un bon d’achat. Délais d’obtention du bon d’achat : 10 jours env.
                </v-alert>
                </v-col>
                <v-col cols="12" md="6" class="pt-6 pa-4">
                <v-layout row wrap>
                    <v-flex xs12>
                        <v-text-field outlined dense class="rounded-lg" prepend-inner-icon="mdi-domain" label="Société à parrainer" 
                            v-model="item.spo_customfields.cpy_name">
                        </v-text-field>
                    </v-flex>
                    <v-flex xs6 class="pr-2">
                        <v-text-field outlined dense class="rounded-lg" prepend-inner-icon="mdi-account-outline" label="Nom" 
                            v-model="item.spo_customfields.usr_lastName">
                            <template v-slot:append><v-icon :color="(item.spo_customfields?.usr_lastName.length) ? 'green' : 'red'">mdi-hexagram</v-icon></template>
                        </v-text-field>
                    </v-flex>
                    <v-flex xs6>
                    <v-text-field outlined dense class="rounded-lg" prepend-inner-icon="mdi-account-outline" label="Prénom" 
                        v-model="item.spo_customfields.usr_firstName">
                        <template v-slot:append><v-icon :color="(item.spo_customfields?.usr_firstName.length) ? 'green' : 'red'">mdi-hexagram</v-icon></template>
                    </v-text-field>
                    </v-flex>
                    <v-flex xs12>
                    <v-text-field outlined dense class="rounded-lg" prepend-inner-icon="mdi-email-outline" label="Courriel" 
                        v-model="item.spo_customfields.usr_email">
                        <template v-slot:append><v-icon :color="(item.spo_customfields?.usr_email.length) ? 'green' : 'red'">mdi-hexagram</v-icon></template>
                    </v-text-field>
                    </v-flex>
                    <v-flex xs12>
                    <v-text-field outlined dense class="rounded-lg" prepend-inner-icon="mdi-cellphone-message" label="Mobile" 
                        v-model="item.spo_customfields.usr_mobile"></v-text-field>
                    </v-flex>
                </v-layout>
            </v-col>
            </v-row>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="rounded-xl" :disabled="!validForm" large color="success" :loading="loading.fetchData" @click.native="sendInvite()">Envoyer</v-btn>
        </v-card-actions>
        </v-card>
</template>

<script>
import { mapWritableState, mapActions } from 'pinia'
import { useSponsorshipStore } from '@/stores/sponsorship'

export default {
    name: 'sponsorship-edit',
    props: [],
    components: { },
    data: function () {
        return {
            loading: { fetchData: true },
            form: {}
        }
    },
    computed: {
        ...mapWritableState(useSponsorshipStore, {item: 'item' }),
        validForm(){
            let valid = true

            valid = (!this.item.spo_customfields?.usr_email.length) ? false : valid
            valid = (!this.item.spo_customfields?.usr_lastName.length) ? false : valid
            if( valid && !this.item.spo_customfields.usr_email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) ){
                valid = false
            }

            return valid
        }
    },
    mounted:function (){
        this.loading.fetchData = false
    },
    methods: {
        ...mapActions(useSponsorshipStore, {fetchItem: 'fetchItem', updateItem: 'updateItem', storeItem: 'storeItem'}),
        async sendInvite () {
            await this.storeItem()
            this.$root.$toast({ color: 'success', text: 'Invitation envoyée !' })
        },
    },
}
</script>