import { defineStore } from 'pinia'
//import permissions from '@/services/permissions'
import ApiService from '@/services/api'
import UserService from '@/services/user'

const ENDPOINT = '/users/'
const ITEM_ID  = 'id'

const ROLES = {
  'BASIC_USER': 'Utilisateur',
  'ADMIN_USER': 'Administrateur 2',
  'SUPER_USER': 'Chef d\'Agence',
  'WORK_SUPERVISOR': 'Conducteur de travaux',
  'TEAM_LEADER': 'Chef d\'équipe',
  'AGENT': 'Ouvrier qualifié',
  'STUDENT': 'Etudiant',
  'CUS_USER': 'Client Utilisateur',
  'CUS_WKSUP_USER': 'Client superviseur',
  'EXTERN_USER': 'Externe',
  'PARTNER_USER': 'Partenaire',
}

export const useUserStore = defineStore('user', {
  //storage option
  persist: true,
  // convert to a function
  state: () => ({
    accessToken: null,
    refreshToken: null,
    item: { password: '', email: '', lastName: '', firstName: '', username: '', },
    me: { id: '', uid: '', companyId: '', firstName: '', lastName: '', middleName: '', spo_id_count: 0 },
    roles: ROLES,
    permissions: {},
    itemList: [],
    itemAllList: [],
    basicRoles: [ 'SUPER_USER', 'WORK_SUPERVISOR', 'TEAM_LEADER', 'AGENT', 'STUDENT' ]
  }),
  getters: {
    getRolesArray(){
      return Object.entries(this.roles).map(entry => { return {value: entry[0], text: entry[1].label} })
    },
    getAuthorizedRolesArray(){
      return Object.entries({
        'BASIC_USER'      : { label: 'Utilisateur', description: ['']},
        'ADMIN_USER'      : { label: 'Administrateur', description: ['']},
        'SUPER_USER'      : { label: 'Chef d\'Agence', description: ['Création d\'utilisateur', 'Gestion client', 'Gestion site', 'Gestion de toutes les tâches']},
        'WORK_SUPERVISOR' : { label: 'Conducteur de travaux', description: ['Gestion client', 'Gestion site', 'Gestion de taches']},
        'TEAM_LEADER'     : { label: 'Chef d\'équipe', description: ['']},
        'AGENT'           : { label: 'Ouvrier qualifié', description: ['']},
        'STUDENT'         : { label: 'Etudiant', description: ['']},
        'CUS_USER'        : { label: 'Client Utilisateur', description: ['']},
        'CUS_WKSUP_USER'  : { label: 'Client superviseur', description: ['']},
        'EXTERN_USER'     : { label: 'Externe', description: ['']},
        'PARTNER_USER'    : { label: 'Partenaire', description: ['']},
      }).map(entry => {
        if( this.me.role === "ADMIN_USER" ){
          return {value: entry[0], text: entry[1].label}
        } else if( this.me.role === "STUDENT" && this.basicRoles.includes(entry[0]) ) {
          return {value: entry[0], text: entry[1].label}
        } else if( this.basicRoles.includes(entry[0]) && (entry[0] !== 'STUDENT') ) {
          return {value: entry[0], text: entry[1].label}
        }
      })
    }
    //accessToken: (state) => state.accessToken,
    //refreshToken (state) {
    //  return state.refreshToken
    //},
    //permissions (state) {
    //  return state.permissions
    //}
  },
  actions: {
    async login ({ login, password }) {
      const requestData = {
        method: 'post',
        url: '/accessToken',
        data: {
          client_id: 2,
          client_secret: process.env.VUE_APP_OAUTH_KEY,
          grant_type: 'password',
          username: login,
          password: password
        }
      }
      const response = await ApiService.customRequest(requestData, true)
      this.SET_ACCESS_TOKEN(response.access_token)
      this.SET_REFRESH_TOKEN(response.refresh_token)
      ApiService.setHeader()
      return response
    },
    async logout () {
      await UserService.logout()
      this.REMOVE_ACCESS_TOKEN
      this.REMOVE_REFRESH_TOKEN
    },
    async refreshAccessToken (state) {
      const requestData = {
        method: 'post',
        url: '/accessToken',
        data: {
          client_id: 2,
          client_secret: process.env.VUE_APP_OAUTH_KEY,
          grant_type: 'refresh_token',
          refresh_token: state.refreshToken
        }
      }
      const response = await ApiService.customRequest(requestData, true)
      this.SET_ACCESS_TOKEN(response.access_token)
      this.SET_REFRESH_TOKEN(response.refresh_token)
    },
    fetchItems (options) {
      return new Promise( (resolve, reject) => {
        ApiService.get( ENDPOINT + options ).then( (response) => {
            this.metaTask = response.data.meta
            if( Object.prototype.toString.call( response.data.data ) === '[object Array]' ) {
              if( options && options.includes('viewall') ){
                this.SET_VIEWALL_ITEMS(response.data.data)
              } else {
                this.SET_ITEMS(response.data.data)
                this.SET_VIEWALL_ITEMS(response.data.data)
              }
            }
            resolve('resolved')
        }).catch( (error) => { 
            reject(error)
        })
    })
      
    },
    fetchItem (id, options) {
      return new Promise( (resolve, reject) => {
        options = (options) ? '/' + options : ''
        if( id && id > 0 ){
          ApiService.get(ENDPOINT + id + options).then( (response) => {
            this.SET_ITEM(response.data.data)
            resolve('resolved')
          }).catch( (error) => { 
            reject(error)
          })
        } else {
          this.RESET_ITEM()
          resolve('resolved')
        }
      })
    },
    fetchMe (options) {
      return new Promise( (resolve, reject) => {
          ApiService.get('/users/me/' + options).then( (response) => {
            this.SET_ME(response.data.data)
            resolve('resolved')
          }).catch( (error) => { 
            reject(error)
          })
      })
    },
    resetPassword(id){
      return new Promise( (resolve, reject) => {
        ApiService.put( '/users/' + id + '/resetpass', {id: id} ).then( () => {
          resolve('resolved')
        }).catch( (error) => {
          reject(error)
        })
      })
    },
    storeItem () {
      return new Promise( (resolve, reject) => {
          ApiService.post( ENDPOINT, this.item ).then( (response) => {
              this.SET_ITEM(response.data)
              resolve(this.item)
          }).catch( (error) => { 
              reject(error)
          })
      })
    },
    updateItem () {
      return new Promise( (resolve, reject) => {
          ApiService.put( ENDPOINT + this.item[ITEM_ID], this.item ).then( (response) => {
              this.SET_ITEM(response.data)
              resolve(this.item)
          }).catch( (error) => { 
              reject(error)
          })
      })
    },
    destroyItem (id) {
      return new Promise( (resolve, reject) => {
          this.item[ITEM_ID] = (id) ? id : this.item[ITEM_ID]
          ApiService.delete(ENDPOINT + this.item[ITEM_ID]).then( () => {
              this.RESET_ITEM()
              resolve('resolved')
          }).catch( (error) => { 
              reject(error)
          })
      })
  },
//    can (state, { action, subject }) {
//      const roles = permissions?.[subject]?.[action] || []
//      return roles.includes(state.user.usr_role)
//    },
//    canViewPage (state, option) {
//      let roles = []
//      let page = (typeof option === 'object') ? option.page : option
//      const read = permissions?.[page]?.['read'] || []
//      const update = permissions?.[page]?.['update'] || []
//      roles = [...read, ...update]
//      if( option.right ){
//        roles = permissions?.[page]?.[option.right] || []
//      }
//  
//      return roles.includes(state.user.usr_role)
//    },
    hasRole (role) {
      return role === this.item.role
    },
    buildPermissionsTable () {
      const userRole = this.item.role
      const table = {}
      Object.keys(this.permissions).forEach((subject) => {
        table[subject] = {}
        Object.keys(this.permissions[subject]).forEach((action) => {
          table[subject][action] = this.permissions[subject][action].includes(userRole)
        })
      })
      this.SET_PERMISSIONS_TABLE(table)
    },
    //***************************MUTATIONS ***************************************/
    SET_ACCESS_TOKEN (token) {
      this.accessToken = token
    },
    REMOVE_ACCESS_TOKEN () {
      this.accessToken = null
    },
    SET_REFRESH_TOKEN (token) {
      this.refreshToken = token
    },
    REMOVE_REFRESH_TOKEN () {
      this.refreshToken = null
    },
    SET_ITEM (item) {
      this.item = item
    },
    SET_ME (item) {
      this.me = item
    },
    SET_ITEMS (itemList) {
      itemList.forEach( (item, index) => {
        itemList[index].middleName = ( !item.middleName ) ? item.lastName + ' ' + item.firstName : item.middleName
      })
      this.itemList = itemList
    },
    SET_VIEWALL_ITEMS (itemList) {
      itemList.forEach( (item, index) => {
        itemList[index].middleName = ( !item.middleName ) ? item.lastName + ' ' + item.firstName : item.middleName
      })
      this.itemAllList = itemList
    },
    RESET_ITEM () {
      this.item = { password: '', email: '', lastName: '', firstName: '', username: '', }
    },
    SET_PERMISSIONS_TABLE (table) {
      this.permissions = table
    }
  }
})
