<template>
    <v-data-table v-bind:headers="headers" :items="itemList" item-key="id" :search="search"
        items-per-page-text="Lignes par page" :hide-default-header="false" :items-per-page-options="pagination.rowsPerItem">
        <template v-slot:top>
            <v-row class="rounded-lg ma-0 pa-0">
                <v-col cols="6" md="6" class="pa-1">
                    <v-text-field append-icon="mdi-magnify" label="Recherche" outlined dense clearable single-line
                        hide-details v-model="search" class="pa-2 rounded-xl">
                    </v-text-field>
                </v-col>
                <v-col cols="1" md="1" class="">
                    <v-btn small rounded :color="(entState == 'GEN01') ? 'grey' : 'blue'" @click="(entState === 'GEN01') ? entState='GEN01,GEN02' : entState='GEN01'; fetchList();">
                        <v-icon v-if="(entState === 'GEN01')" title="Afficher les clients en veille" class="hidden-md-and-up mr-1 mr-1">mdi-sleep</v-icon>
                        <v-icon v-else title="Afficher les clients en veille" class="hidden-md-and-up mr-1 mr-1">mdi-sleep-off</v-icon>
                        <span class="hidden-sm-and-down">Afficher les clients en veille</span>
                    </v-btn>
                </v-col>
                <v-col cols="5" md="5" class="text-right">
                    <v-btn small rounded color="primary" href="#/customers/0">
                        <v-icon title="Ajouter un client" class="hidden-md-and-up mr-1 mr-1">mdi-plus</v-icon>
                        <span class="hidden-sm-and-down">Ajouter un client</span>
                    </v-btn>
                </v-col>
            </v-row>
        </template>
        <template v-slot:[`item.ent_name`]="{ item }"><v-chip class="ma-2" color="secondary">{{ item.ent_name }}</v-chip><v-icon>mdi-arrow-right</v-icon></template>
        <template v-slot:[`item.ent_typ_id`]="{ item }">
            <v-chip class="ma-2" outlined :color="(item.ent_sta_id=='GEN02') ? 'red' : typeListById[item.ent_typ_id].typ_color">
                <v-icon>mdi-face-agent</v-icon>&nbsp;<v-icon v-if="(item.ent_sta_id=='GEN02')">mdi-sleep</v-icon>{{ typeListById[item.ent_typ_id].typ_name }}</v-chip>
        </template>
        <template v-slot:[`item.sit_id_count`]="{ item }">
            <v-chip class="ma-2" outlined :color="(item.sit_id_count > 1) ? 'green' : 'grey'" title="Site(s)">
                <v-icon :title="(!item.ent_lat) ? 'Client / site non géolocalisé' : ''">{{ (!item.ent_lat) ? 'mdi-map-marker-off-outline' : 'mdi-map-marker-multiple'}}</v-icon>&nbsp;{{ item.sit_id_count }}
            </v-chip>
        </template>
        <template v-slot:[`item.ent_id`]="{ item }">
            <v-btn v-if="$root.$permissions.Customer.show" rounded :href="'/#/customers/' + item.ent_id + ''" color="orange" elevation="0" dark>
                <v-icon left v-if="$root.$permissions.Customer.show" >{{item.ent_id}} mdi-pencil</v-icon> Fiche
            </v-btn>
        </template>
        <template v-slot:no-data>
        <v-alert :value="( !itemList.lenght )" color="error" icon="mdi-alert">
            Aucun evenement
        </v-alert>
        </template>
    </v-data-table>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useEntityStore } from '@/stores/entity'
import { useTypeStore } from '@/stores/type'
//import { useStateStore } from '@/stores/state'

export default {
    name: 'entities-list',
    computed: {
        ...mapState(useEntityStore, {itemList: 'itemList' }),
        ...mapState(useTypeStore, {typeListByGroup: 'itemListByGroup', typeListById: 'itemListById' }),
    },
    data: function () {
        return {
            search: '',
            pagination: { totalItems: 0, rowsPerPage: 10, rowsPerItem: [15,25,35,{text:'Tout',value:-1}] },
            headers: [
                { text: 'Nom',     value: 'ent_name', align: 'left'},
                { text: 'Type',    value: 'ent_typ_id', align: 'left'},
                { text: 'Nb Site', value: 'sit_id_count', align: 'left'},
                { text: 'Action',  value: 'ent_id', align: 'right'},
            ],
            entState: 'GEN01'
        }
    },
    async mounted () {
        this.fetchList()
    },
    methods: {
        ...mapActions(useEntityStore, {fetchItems: 'fetchItems'}),
        async fetchList(){
            await this.fetchItems('?per_page=false&extended=withcountsite&ent_sta_id=' + this.entState)
        }
    },
}
</script>