<template>
    <v-data-table v-bind:headers="headers" :items="itemList" item-key="id" :search="search"
        items-per-page-text="Lignes par page" hide-default-header
        :items-per-page-options="pagination.rowsPerItem">
        <template v-slot:top>
            <v-row class="rounded-lg ma-0 pa-0">
                <v-col cols="6" md="6" class="pa-1">
                <v-text-field append-icon="mdi-magnify" label="Recherche" outlined dense clearable single-line
                            hide-details v-model="search" class="pa-2 rounded-xl">
                </v-text-field>
                </v-col>
            </v-row>
        </template>
        <template v-slot:[`item.str_name`]="{ item }"><v-chip class="ma-2" color="secondary">{{ item.str_name }}</v-chip><v-icon>mdi-arrow-right</v-icon></template>
        <template v-slot:[`item.str_id`]="{ item }">
            <v-btn v-if="$root.$permissions.Store.update" rounded :href="'/#/stores/' + item.str_id + ''" color="orange" elevation="0" dark>
                <v-icon left  v-if="$root.$permissions.Store.update" >{{item.str_id}} mdi-pencil</v-icon> Fiche
            </v-btn>
        </template>
        <template v-slot:no-data>
        <v-alert :value="( !itemList.lenght )" color="error" icon="mdi-alert">
            Aucun evenement
        </v-alert>
        </template>
    </v-data-table>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useStoreStore } from '@/stores/store'

export default {
    name: 'stores-list',
    computed: {
        ...mapState(useStoreStore, {itemList: 'itemList' })
    },
    data: function () {
        return {
            search: '',
            pagination: { totalItems: 0, rowsPerPage: 10, rowsPerItem: [15,25,35,{text:'Tout',value:-1}] },
            headers: [
                { text: 'Nom',    value: 'str_name', align: 'left'},
                { text: 'Type',   value: 'str_typ_id', align: 'left'},
                { text: 'Action', value: 'str_id', align: 'right'},
            ],
        }
    },
    async mounted () {
        await this.fetchItems('?per_page=false')
    },
    methods: {
        ...mapActions(useStoreStore, {fetchItems: 'fetchItems'}),
    },
}
</script>