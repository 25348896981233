import L    from 'leaflet';
// eslint-disable-next-line no-unused-vars
import MarkerCluster from 'leaflet.markercluster'
// eslint-disable-next-line no-unused-vars
import LeafleatPm    from 'leaflet.pm';

import { bus } from '@/plugins/bus'

class LfgMap {
    constructor(mapContainer) {
      this.layerUrl = 'https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png'
      this.mapContainer = mapContainer
      this.baseLayerMap = this._setBaseLayer()
      this.Map          = null
      this.leaflet       = window.L
      this.defautLatLng  = [47.47479783478821, 1.053092956542968]
      this.markerLoc     = [0, 0]
      this.markerDrag    = null
      this.circleMarker  = null
      this.svgIcon       = { gen: '<div class="marker-svg marker-gen"><center></center></div>' }
      this.component     = {};
      /************* LAYERS *************/
      this.baseLayer     = {}
      /************* GEOJSON *************/
      this.geoJson       = {}
      /************* ******* *************/
      this.modeAddSite   = false;
    }

    display(latlng, zoom, projection) {
      latlng     = latlng || this.defautLatLng;
      zoom       = zoom   || 6;
      this.zoom  = zoom;
      projection = projection || 'EPSG3857';
      this.projection = projection;

      var mapOptions = {
          zoom: zoom,
          zoomControl:false,
          center: latlng,
          crs: L.CRS[ projection ],
          preferCanvas: true,
          tapTolerance: 20,
          maxZoom : 28, minZoom: -20, maxNativeZoom: 16,
          layers: [this.baseLayerMap],
      };

      if( latlng !== undefined ){
          mapOptions.center = latlng
      }
      if( zoom !== undefined ){
          mapOptions.zoom = zoom
      }
      // Init MAP
      this.Map = new this.leaflet.map(this.mapContainer, mapOptions);
      
      // Map on Click (Add site)
      this.Map.on('click', (e) => {
        this.addLocMarker( { lat: e.latlng.lat, lng: e.latlng.lng } );
      })

      return this.Map;
    }

    // Ajouter un marqeur de géolocalisation
    addLocMarker(pos) {
      if (!pos.lat || pos.lat == 0 || pos.lng == 0) {
        this.Map.locate({setView: true, maxZoom: 16});
        this.Map.on('locationfound', (e) => {
          this.markerDrag.setLatLng(new this.leaflet.LatLng(e.latitude, e.longitude));
        });
        pos = { lat : this.defautLatLng[0], lng : this.defautLatLng[1] }
      }
      if ((this.markerDrag == null || this.markerDrag == undefined) ) {
        this.markerDrag = this.buildMarker({ draggable: true, type: 'gen', color: '#2196F3', latLng: [pos.lat, pos.lng] });
        this.Map.addLayer(this.markerDrag);
      } else {
        this.markerDrag.setLatLng(new this.leaflet.LatLng(pos.lat, pos.lng))
        bus.$emit('marker:moved', pos)
      }
      this.markerDrag.on({
        dragend: function (e) {
          bus.$emit('marker:moved', e.target._latlng)
        }
      })

      this.Map.setView( [pos.lat, pos.lng], 15, {
        pan: { animate: true, duration: 1.50 },
        zoom: { animate: true }
      });

    }

    buildMarker(obj) {
      let title     = obj.title || '';
      let popup     = obj.popup || '';
      let draggable = obj.draggable || false
      let opacity   = obj.opacity || 0
      let marker    = L.marker(obj.latLng, { //this.leaflet.marker(obj.latLng, {
        title: title,
        icon: this.buildIcon(obj),
        draggable: draggable
      });
      marker.on('dragend', () => {
        var position = marker.getLatLng();
        this.markerLoc = [ position.lat, position.lng ];
      });
      if( popup.length > 0 ){
        marker.bindPopup(popup);
      }
      if(opacity){
        marker.setOpacity(opacity)
      }

      return marker;
    }

    buildIcon(obj) {
      let type = obj.type;
      let html = '<div class="marker-svg marker-blue"><center></center></div>';
      switch(type) {
        case 'gen':
          if( obj.color ){
            html = '<div><svg style="fill:' + obj.color + ';" xmlns="http://www.w3.org/2000/svg" viewBox="1239.2 -915.6 332.5 593">';  
          } else {
            html = '<div><svg xmlns="http://www.w3.org/2000/svg" viewBox="1239.2 -915.6 332.5 593">';  
          }
          html += '<path opacity=".2" d="M1544.6-459c-46.1-15.4-305.3-36.5-305.3 13.4v3.8c0 48 163.2 119.1 163.2 119.1 184.3-99.8 193.9-119 142.1-136.3z"/>';
          html += '<path d="M1398.6-339.9s-159.4-272.7-159.4-458.9 251.6-107.5 297.6-49.9c50 63.3 40.4 140.1-138.2 508.8z" />';
          html += '<path fill="#000" opacity=".3" d="M1398.6-339.9s-159.4-272.7-159.4-458.9 251.6-107.5 297.6-49.9c50 63.3 40.4 140.1-138.2 508.8z" />';
          html += '<path d="M1398.6-339.9s-159.4-265-159.4-447.4 234.3-105.6 276.5-49.9c48.1 61.4 55.7 97.9-117.1 497.3z" />';
          if( obj.text && obj.text.length ){
            html += '<ellipse style="fill:#ffffff;stroke:#33756e;stroke-width:0.68801081;stroke-miterlimit:10" ry="98.495834" rx="151.41518" id="circle10" cy="-759.33984" cx="1392.7476" stroke-miterlimit="10" />';
            html += '<text transform="scale(0.89469964,1.1176935)" id="text824" y="-637.34656" x="1560.7899"';
            html += ' style="font-style:normal;font-variant:normal;font-weight:bold;font-stretch:normal;font-size:36.66627502px;line-height:1.25;font-family:sans-serif;-inkscape-font-specification:\'sans-serif Bold\';letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;stroke-width:1.3749851"';
            html += ' xml:space="preserve"><tspan';
            html += ' style="font-style:normal;font-variant:normal;font-weight:bold;font-stretch:normal;font-size:117.33207703px;font-family:sans-serif;-inkscape-font-specification:\'sans-serif Bold\';text-align:center;text-anchor:middle;stroke-width:1.3749851"';
            html += ' y="-637.34656" x="1560.7899" id="tspan822">' + obj.text + '</tspan></text>';
          } else {
            html += '<circle fill="#FFF" stroke="#33756E" stroke-width=".4" stroke-miterlimit="10" cx="1392.9" cy="-731.7" r="71" /></svg>';
          }

          if( obj.marker ){
            html += '<img src="./' + obj.marker + '.svg" style="width: 25px; margin: 0 auto; position:absolute; top:4px; right:6px;">';
          }
          html += '<div>';
          break;
        default:
          var p = type.split("-");
          if( p[1] !== undefined ){
            html= '<div class="marker-svg marker-' + p[0] + '"><img src="./' + p[1] + '.svg" style="width: 25px; margin: 0 auto; position:absolute; top:4px; right:6px;"></div>';
          } else {
            html = this.svgIcon['blue'];
          }
      }
      let iconSize    = obj.iconSize || [34, 56]
      let iconAnchor  = obj.iconAnchor || [17, 55]
      let popupAnchor = obj.popupAnchor || [0, -56]
      return this.leaflet.divIcon({ 
        html: html, 
        iconSize   : iconSize,
        iconAnchor : iconAnchor,
        popupAnchor: popupAnchor,
        className: 'marker-transp'
      });
    }

    _setBaseLayer() {
      return L.tileLayer('https://{s}.tiles.technosig.fr/' + 'rj3ry0gjlkxx7feia0mx43ut' + '/wmts/osmtiles/webmercator/{z}/{x}/{y}.png', { //this.layerUrl
        attribution: 'TECHNOSIG',
        maxNativeZoom: 18, maxZoom : 24,
        id: 'osm'
      });
    }

}

export default LfgMap;