<template>
<v-container ref="tskContainer" id="taskZone" fluid class="pa-0" :style="(!dialogSign) ? 'max-height: calc(100% - 100px); overflow-y: auto;' : ''">
    <!-- <v-container fluid :class="{'mt-0 pa-0': $vuetify.breakpoint.smAndDown, 'mt-0 pa-0': $vuetify.breakpoint.mdAndUp}" style="max-height: calc(100% - 48px);"> -->
    <!-- Associative Tab --------------------------------------------------------------------------------------------------------->
    <v-card v-if="dialogSign==0" fluid style="max-height: 100%; " id="taskCard" ref="taskCardref"
        :class="($vuetify.breakpoint.smAndDown) ? '' : $root.$style.cardFormClass" @wheel="handleScroll" @touchstart="handleScroll" @touchend="handleScroll"> <!-- @wheel="handleScroll" @touchstart="handleScroll" @touchend="handleScroll" -->
        <v-row no-gutters>
            <v-col cols="2" md="1" style="max-width: 65px; border-right: 1px solid grey;" :class="{'pa-2' : $vuetify.breakpoint.smAndDown}">
                <v-tabs v-if="showTab" v-model="activeTab" grow light show-arrows="mobile" slider-size="1" vertical class="mt-4" style="border-bottom-left-radius: 40px !important;">
                <v-tabs-slider color="green"></v-tabs-slider>
                <v-tab v-for="item in tabItems" :key="item.title" :disabled="item.disabled"
                    class="pl-3 pr-1 pb-4 rounded-lg" style="border-bottom-left-radius: 20px !important; background-color:white;"> <!-- border-bottom-left-radius: 20px !important; -->
                    <span style="writing-mode: vertical-rl;">
                        <v-icon class="mr-1" :color="(activeTab.event == 0) ? 'red'  : 'grey'">{{item.icon}}</v-icon>
                        <span class="hidden-sm-and-down" style="writing-mode: vertical-rl;">
                            {{ item.title }}
                        </span>
                    </span>
                </v-tab>
                </v-tabs>
                <v-btn v-if="(this.$route.params.tsk_uid.length > 8)" tile small class="ma-1 rounded-xxl" @click="activeTab++">
                    <v-icon>mdi-arrow-down-bold</v-icon>
                </v-btn>
            </v-col>
            <v-col cols="10" md="11" class="rounded-xxl">
            <v-card v-if="activeTab === 0" class="elevation-0 rounded-xxl" fluid light>
            <v-card-text class="rounded-xxl">
                <v-form ref="userForm" :disabled="disableForm" lazy-validation>
                    <v-row no-gutters row wrap class="ma-0 mt-2">
                    <v-col cols="8" md="4" class="pl-2 pr-2">
                        <DatePicker v-model="item.tsk_date_plan" label="Planifier le" :objectForm="item" fieldName='tsk_date_plan'></DatePicker>
                    </v-col>
                    <v-col cols="4" md="2" class="pl-2 pr-2">
                        <v-text-field v-if="item.tsk_time_plan" label="H:M" outlined dense class="rounded-lg" v-model="item.tsk_time_plan" type="time"></v-text-field>
                    </v-col>
                    <v-col cols="8" md="4" class="pl-2 pr-2">
                        <DatePicker v-model="item.tsk_date_end" label="Date de fin" :objectForm="item" fieldName='tsk_date_end'></DatePicker>
                    </v-col>
                    <v-col cols="4" md="2" class="pl-2 pr-2">
                        <v-text-field label="H:M" outlined dense class="rounded-lg" v-model="item.tsk_time_end" type="time"></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6" class="pl-2 pr-2">
                        <v-select v-if="stateListById[item.tsk_sta_id]" :disabled="( !($root.$permissions.Task.before) || disableForm)"
                            :dense="fieldStyle.dense" :outlined="fieldStyle.outlined" item-text="sta_label" item-value="sta_id"
                            :items="stateListByGroup['TSK']" v-model="item.tsk_sta_id" label="Statut" single-line>
                            <template v-slot:prepend><v-icon :color="stateListById[item.tsk_sta_id].sta_color">mdi-file-tree</v-icon></template>
                        </v-select>
                    </v-col>
                    <v-col cols="12" md="6" class="pl-2 pr-2">
                    <v-select 
                        :dense="fieldStyle.dense" :outlined="fieldStyle.outlined" item-text="typ_name" item-value="typ_id"
                        :items="typeListByGroup['TSK']" v-model="item.tsk_typ_id" label="Type" single-line>
                        <template v-slot:prepend><v-icon :color="typeListById[item.tsk_typ_id].typ_color">mdi-progress-check</v-icon></template>
                    </v-select>
                    </v-col>
                    <v-col cols="12" md="12" class="pl-2 pr-2">
                        <AutocompleteMulti 
                            :title="'Actions'" :ressource="'tasktypes'" :disabled="disableForm"
                            :listObject.sync="item._pivot.tasktypes" :listParent.sync="autoCompleteSource.listTaskTypeItemSource" 
                            :fields="{ 
                                index: 'tty_id', text:'tty_name', autofocus: false, deletablechips: true,
                                search:'tty_name', preprendIcon:'mdi-table-merge-cells', multiSelect:true, prefetch: false, params:'' 
                        }"></AutocompleteMulti>
                    </v-col>
                    <v-col cols="12" md="12" class="pl-2 pr-2">
                        <AutocompleteMulti 
                            :title="'Agent(s) affectés'" :ressource="'users'" :disabled="disableForm"
                            :listObject.sync="item._pivot.users" :listParent.sync="autoCompleteSource.userList" 
                            :fields="{
                                index: 'id', text:'lastName,firstName', autofocus: false, deletablechips: true,
                                search:'middleName', preprendIcon:'mdi-account-group', multiSelect:true, prefetch: false, params:'' 
                        }"></AutocompleteMulti>
                    </v-col>
                    <v-col cols="12" md="12" class="pl-2 pr-2">
                        <AutocompleteMulti 
                            :title="'Materiel'" :ressource="'equipments'" :disabled="disableForm"
                            :listObject.sync="item._pivot.equipments" :listParent.sync="autoCompleteSource.listEquipmentItemSource" 
                            :fields="{ 
                                index: 'equ_id', text:'equ_name', autofocus: false, deletablechips: true,
                                search:'equ_name', preprendIcon:'mdi-tools', multiSelect:true, prefetch: true, params:'' 
                        }"></AutocompleteMulti>
                    </v-col>
                    <v-col cols="12" md="4" class="pl-2 pr-2">
                        <v-text-field outlined dense class="rounded-lg" prepend-icon="mdi-progress-clock" label="Temps prévu" v-model="item.tsk_duration_plan" type="time" suffix="H:M"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" class="pl-2 pr-2">
                        <v-text-field outlined dense class="rounded-lg" prepend-icon="mdi-clock-check-outline" label="Temps effectif" v-model="item.tsk_duration" type="time" suffix="H:M"></v-text-field>
                    </v-col>
                    <v-col cols="0"  md="1" class="pl-2 pr-2"></v-col>
                    <v-col cols="12" md="3" class="pl-2 pr-2">
                        <v-text-field outlined dense class="rounded-lg" prepend-icon="mdi-currency-eur" label="Tarif" v-model="item.tsk_amount_ht" type="number" suffix="€"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12"  class="pl-2 pr-2">
                        <v-textarea :dense="fieldStyle.dense" :outlined="fieldStyle.outlined" prepend-icon="mdi-comment"
                            rows="2" label="Commentaire"  v-model="item.tsk_customfields.tsk_comment" >
                        </v-textarea>
                    </v-col>
                </v-row>
                </v-form>
            </v-card-text>
        </v-card>
        <WorktimeTab v-if="activeTab === 1" :task="item" />
        <!-- <EquipmentTab v-if="activeTab === 2" :itemList="item.equipments" /> -->
        <DocumentTab v-if="activeTab === 2" :routeState="routeState" :doc_xxx_id="item.tsk_id" :table_id="'tsk'" docType="DOC01,DOC02" @linkDoc="linkedDocument"></DocumentTab>
        <DocumentTab v-if="activeTab === 3" :routeState="routeState" :doc_xxx_id="item.tsk_id" :table_id="'tsk'" docType="DOC05" @linkDoc="linkedDocument"></DocumentTab>
        <DocumentTab v-if="activeTab === 4" :routeState="routeState" :doc_xxx_id="item.tsk_id" :table_id="'tsk'" docType="DOC03" @linkDoc="linkedDocument"></DocumentTab>
        <!-- <VueSignaturePad v-if="activeTab === 5" width="500px" height="500px" ref="signaturePad" style="border: 2 solid red" />-->
        <!-- <EventTab v-if="activeTab === 5" :routeState="routeState" :eve_xxx_id="item.tsk_id" :table_id="'tsk'" typfilter="" :genfilter="false" title="Evenements"></EventTab>-->
        </v-col>
        </v-row>
    </v-card>
    <!-- Save button form -->
    <v-toolbar v-show="!mailNotify && dialogSign==0" extended flat color="transparent" bottom height="48px">
        <v-btn v-if="(item.tsk_uid && item.tsk_sta_id !== 'TSK07')" rounded color="red" class="mr-3" dark :loading="loading.fetchData" @click.native="deleteItem()" :disabled="false">
        <v-icon left>mdi-trash-can-outline</v-icon>
        </v-btn>
        <v-btn v-if="(item.ent_notify && item.tsk_uid && item.tsk_sta_id !== 'TSK05' && !disableForm)" rounded color="green" class="mr-3" dark :loading="loading.fetchData" @click.native="mailNotify = 1" :disabled="false">
            <v-icon left>mdi-email-arrow-right-outline</v-icon>Terminer
        </v-btn>
        <v-btn v-if="(!item.ent_notify && item.tsk_uid && item.tsk_sta_id !== 'TSK05' && !disableForm)" rounded color="green" class="mr-3" dark :loading="loading.fetchData" @click.native="item.tsk_sta_id = 'TSK05'; saveItem();" :disabled="false">
            <v-icon left>mdi-record</v-icon>Terminer
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn v-if="!disableForm" rounded text color="#595959" dark @click="cancelEdit()" ref="taskCancel">
            <!--<v-icon left dark>mdi-close</v-icon>-->Annuler
        </v-btn>
        <v-btn v-if="!disableForm" rounded color="primary" class="mr-3 ml-3" :loading="loading.fetchData" @click.native="execSaveItem()" :disabled="(loading.fetchData) ? true : false">
            <!--<v-icon left>mdi-content-save-outline</v-icon>-->Enregistrer
        </v-btn>
        <v-btn v-if="disableForm" rounded color="orange" class="mr-3 ml-3" :loading="loading.fetchData" @click.native="unlockItem('TSK04')" :disabled="(loading.fetchData) ? true : false">
            <v-icon left>mdi-lock-open-outline</v-icon>Ré-ouvrir
        </v-btn>
    </v-toolbar>
    <!-- Save button form -->
    <v-toolbar v-if="mailNotify" extended flat color="transparent" bottom height="48px">
        <v-text-field outlined dense class="rounded-lg mt-2" prepend-icon="mdi-email-arrow-right-outline" v-model="item.email_notify"></v-text-field>
        <v-switch v-model="item.ent_notify" label="Envoyer par mail" class="ml-2"></v-switch>
        <v-spacer></v-spacer>
        <v-btn v-if="(item.tsk_uid && item.tsk_sta_id !== 'TSK05' && !disableForm)" rounded color="green" class="mr-3" dark :loading="loading.fetchData" @click.native="item.tsk_sta_id = 'TSK05'; saveItem();" :disabled="false">
            <v-icon left>mdi-record</v-icon>Envoyer et Terminer
        </v-btn>
    </v-toolbar>
    <!-- </v-container>-->
    <!-- Modal signature -->
    <v-card v-show="dialogSign" style="max-height: 600px">
        <v-toolbar dark color="primary" dense text>
            <v-toolbar-title class="white--text">Signature {{ (dialogSign==1) ? 'paysagiste' : 'client' }}</v-toolbar-title>
        </v-toolbar>
        <VueSignaturePad width="500px" height="400px" ref="signaturePad" style="border: 1px solid red;" />
    </v-card>
    <v-toolbar v-if="dialogSign" extended flat color="transparent" bottom height="48px">
        <v-spacer></v-spacer>
        <v-btn color="red" v-show="dialogSign==1" @click="saveSignature()"><v-icon title="Suivant">mdi-next</v-icon>Suivant</v-btn>
        <v-btn color="red" v-show="dialogSign==2" @click="saveSignature()"><v-icon title="Terminer">mdi-next</v-icon>Terminer</v-btn>
    </v-toolbar>
</v-container>
</template>

<script>
import DatePicker from '@/components/ui/DatePicker'
import AutocompleteMulti from '@/components/ui/AutocompleteMulti'
//import EventTab    from '@/components/events/EventList.vue'
//import EquipmentTab  from '@/components/equipments/EquipmentTab.vue'
import DocumentTab from '@/components/documents/DocumentList.vue'
import WorktimeTab from '@/components/worktimes/WorktimeList.vue'

import { mapState, mapWritableState, mapActions } from 'pinia'
import { useTaskStore } from '@/stores/task'
import { useTypeStore } from '@/stores/type'
import { useStateStore } from '@/stores/state'
import { useTaskTypeStore } from '@/stores/tasktype'
import { useUserStore } from '@/stores/user'

import { taskUpdateService } from '@/rxjsServices';

export default {
    name: 'task-edit',
    props: [ 'routeState', 'link' ],
    mixins: [ ],
    components: {
        DatePicker, AutocompleteMulti, //EventTab,
        DocumentTab,
        WorktimeTab,
        //EquipmentTab
    },
    computed: {
        ...mapWritableState(useTaskStore, {item: 'item' }),
        ...mapState(useTypeStore, {typeListByGroup: 'itemListByGroup', typeListById: 'itemListById' }),
        ...mapState(useStateStore, {stateListByGroup: 'itemListByGroup', stateListById: 'itemListById' }),
        ...mapState(useTaskTypeStore, {taskTypeList: 'itemList' }),
        ...mapState(useUserStore, {userList: 'itemList', user: 'me' }),
        disableForm(){
            if(this.loading.fetchData){
                return true
            } else {
                return (this.item.tsk_sta_id == 'TSK05')
            }
        }
    },
    data: function () {
        return {
            loading : { fetchData: true },         // Enregistrement en cours...
            fieldStyle: { outlined: true, dense: true },
            activeTab: -1, 
            showTab: false,
            mailNotify: false,
            tabItems: [
                { title: 'Fiche',      disabled: false, icon: 'mdi-file-document-multiple-outline'},
                { title: 'Temps',      disabled: !this.isAuthorized(), icon: 'mdi-clock-time-eight-outline'},
                //{ title: 'Materiel',   disabled: false, icon: 'mdi-tools'},
                { title: 'Fichiers',   disabled: false, icon: 'mdi-folder-multiple-outline'},
                { title: 'Avant',   disabled: false, icon: 'mdi-camera'},
                { title: 'Apès',   disabled: false, icon: 'mdi-camera'},
                //{ title: 'Sign',   disabled: false, icon: 'mdi-card'},
                //{ title: 'Evenements', disabled: false, icon: 'mdi-calendar-alert'},
            ],
            autoComplete: { listTaskTypeItem: {}, },
            autoCompleteSource: { listTaskTypeItemSource: [], userList: [], listEquipmentItemSource: [] },
            unlockedItem: false,
            touchstartX: 0, touchstartY: 0, touchendX: 0, touchendY: 0,
            elemSlider: document.getElementById('taskZone'),
            elemPosition: 'scroll',
            validForm: true,
            nameRules: [ v => !!v || 'Le champ doit être renseigné', v => (v && v.length <= 100) || 'Doit être inférieur à 100 charactères', ],
            emailRules: [ v => !!v || 'E-mail requis', v => /.+@.+\..+/.test(v) || 'E-mail doit être valide', ],
            dialogSign: 0
        }
    },
    async mounted (){
        this.autoCompleteSource.listTaskTypeItemSource = this.taskTypeList
        this.autoCompleteSource.userList = this.userList

        await this.fetchItem( this.$route.params.tsk_uid, '?include=usercompany,tasktypes,users,equipments,site,entity' )
        // init form
        if( !(this.$route.params.tsk_uid.length > 8) ){
            this.item.tsk_date_plan = this.$date().format('YYYY-MM-DD')
            this.item.tsk_time_plan = this.$date().format('HH:mm')
            this.item._pivot.tasktypes.push( this.autoCompleteSource.listTaskTypeItemSource[0].tty_id )
            this.item._pivot.users.push(this.user.id)
        }
        this.item.ent_notify = (this.item?.site?.entity?.ent_notify) ? 1 : 0
        this.item.email_notify = (this.item?.site?.entity?.ent_customfields?.ent_email) ? this.item?.site?.entity?.ent_customfields?.ent_email : ''

        this.loading.fetchData = false
        this.item.tsk_sit_id = this.$route.params.sit_id
        this.activeTab = 0
        this.showTab      = ( this.item.tsk_id ) ? true : false

        this.elemSlider = document.getElementById('taskZone')
        document.getElementById('taskZone').addEventListener('scroll', (tt) => {
            //console.log(this.elemSlider.offsetHeight + '+' + tt.target.scrollTop + '+' + tt.target.scrollHeight)
            let heightScroll = this.elemSlider.offsetHeight + tt.target.scrollTop
            let offsetHeight = this.elemSlider.offsetHeight
            this.elemPosition = 'scroll'
            if( heightScroll == offsetHeight ){
                setTimeout(() => {
                    this.elemPosition = 'top'
                }, 300)
            } else if( (this.elemSlider.offsetHeight + tt.target.scrollTop) >=  tt.target.scrollHeight ){
                setTimeout(() => {
                    this.elemPosition = 'bottom'
                }, 300)
            }
        })
    },
    methods: {
        ...mapActions(useTaskStore, { fetchItem: 'fetchItem', updateItem: 'updateItem', storeItem: 'storeItem', destroyItem:'destroyItem' }),
        async saveItem() {
            this.$root.$confirm(
                'Terminer', 
                'Confirmer : terminer l\'action ? <br /> Vous avez la possibilité de signer (vous et le client)', { color: 'primary', width: 400 },
                [
                    { color: 'blue darken-1', value: 'sign', text: 'Signer', icon: 'mdi-signature-freehand' },    
                    { color: 'success darken-1', value: true, text: 'Terminer' },
                    { color: 'grey', value: false, text: 'Annuler' }
                ])
                .then((confirm) => {
                    if( confirm == 'sign' ){
                        this.dialogSign = 1
                    } else if( confirm === true ){
                        this.execSaveItem()
                    }

            })
        },
        async saveSignature(){
            const { isEmpty, data } = await this.$refs.signaturePad.saveSignature('image/svg+xml');
            if( this.dialogSign == 1 ){
                this.item.tsk_sign_worker = data
            } else if( this.dialogSign == 2){
                this.item.tsk_sign_customer = data
                this.execSaveItem()
            }
            if( !isEmpty ){
                this.dialogSign = (this.dialogSign == 1 ) ? 2 : 0
            }

            this.$refs.signaturePad.clearSignature()
        },
        async execSaveItem () {
            // Enregistrement du formulaire
            let toStore = false
            this.loading.fetchData = true
            this.item.tsk_tty_id = this.item._pivot.tasktypes[0]
            if( this.$route.params.tsk_uid.length > 8 ){
                await this.updateItem()
                taskUpdateService.sendTask(this.item);
            } else {
                await this.storeItem()
                taskUpdateService.sendTask(this.item);
                toStore = true
            }
            if( !this.unlockedItem && !toStore ){
                this.$router.push({ path: this.$route.fullPath.split('/').slice(0, -1).join('/') })
            } else if(toStore) {
                this.$router.push({ path: this.$route.fullPath.split('/').slice(0, -1).join('/') + '/' + this.item.tsk_uid })
            } else {
                await this.fetchItem( this.item.tsk_uid, '?include=usercompany,tasktypes,users,equipments' )
            }
            this.$root.$toast({ text: 'Fiche enregistrée !' })
            this.unlockedItem = false
            this.loading.fetchData = false
        },
        async unlockItem(){
            this.$root.$confirm('Supprimer', 'Confirmer la ré-ouverture de l\'action ?', { color: 'red', width: 400 }, null).then((confirm) => {
                if( confirm ){
                    this.item.tsk_sta_id = 'TSK04'
                    this.unlockedItem = true
                    this.execSaveItem()
                }
            })
        },
        async deleteItem(){
            this.$root.$confirm('Supprimer', 'Confirmer la suppression ?', { color: 'red', width: 400 }).then((confirm) => {
                if( confirm ){
                    this.item.tsk_sta_id = 'TSK07'
                    this.destroyItem()
                    this.$root.$toast({ color: 'error', top:false, bottom:true, right:false, left:true, text:'Tache supprimée !' })
                    taskUpdateService.sendTask(this.item)
                    this.$router.push({ path: this.$route.fullPath.split('/').slice(0, -1).join('/') })
                }
            })
        },
        async cancelEdit(){
            this.$router.push({ path: this.$route.fullPath.split('/').slice(0, -1).join('/') })
        },
        linkedDocument(doc_id){
            this.item.tsk_doc_id = doc_id
        },
        isAuthorized(){
            return (this.$root.$userInfo.usercompany.cpy_sub_typ_id == 'SUB03' || this.$root.$userInfo.usercompany.cpy_sub_typ_id == 'SUB04')
        },
        startScroll(event){
            this.touchstartX = event.screenX
            this.touchstartY = event.screenY
        },
        handleScroll(event){
            if( this.item.tsk_id ){
                let isOverfllow = (( document.getElementById('taskCard').clientHeight ) > document.getElementById('taskZone').clientHeight)

                if( event.type == 'wheel' && (!isOverfllow || this.elemPosition !== 'scroll') ){
                    if ( event.deltaY < 0 && (!isOverfllow || this.elemPosition !== 'bottom') ){
                        this.activeTab--
                    } else if ( event.deltaY > 0 && (!isOverfllow || this.elemPosition !== 'top') && (this.activeTab < (this.tabItems.length - 1) )  ) {
                        this.activeTab++
                    }
                }

                if( event.type=='touchstart' ){
                    this.touchstartY = event.touches[0].clientY
                } else if( event.type=='touchend' ){
                    this.touchendY = event.changedTouches[0].clientY
                }

                if( event.type=='touchend' && (!isOverfllow || this.elemPosition !== 'scroll') ){
                    if (this.touchendY < this.touchstartY) {
                        this.activeTab++
                    }
                    if (this.touchendY > this.touchstartY) {
                        this.activeTab--
                    }
                    this.touchendX = 0
                    this.touchendY = 0
                    this.touchstartX = 0
                    this.touchstartY = 0
                }
            }
        },
    }
}
</script>
<style>
.v-tabs--vertical {
  align-items: end;
  flex-direction: column;
}
/*.v-tabs-slider-wrapper {
  left: auto !important;
  right: 0;
}*/
.v-tabs--vertical > .v-tabs-bar .v-tab {
    height: auto;
}
.v-tab {
    justify-content: left;
}
</style>