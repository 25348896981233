<template>
    <v-container fluid>
        <v-card fluid class="ma-2 pa-3 elevation-0 rounded-xxl micorise-v-card">
        <v-row no-gutters>
            <v-col cols="12" md="12" class="pr-2">
                <v-card class="elevation-1 ma-2">
                    <v-toolbar flat dark color="secondary" >
                        <v-toolbar-title><v-icon>mdi-file-excel</v-icon>Export par tache</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-icon>mdi-download</v-icon>
                    </v-toolbar>
                    <v-card-text>
                    <v-row no-gutters>
                        <v-col cols="6" md="6" class="pr-2">
                            <AutocompleteMulti 
                            :title="'Client'"
                            :listObject.sync="reports.tasks.sit_ent_id" :listParent.sync="listEntities" 
                            ressource="entities"
                            :fields="{ 
                                index: 'ent_id', text:'ent_name', autofocus: true, outlined: true, dense: true,
                                search:'ent_name', multiSelect:true, prefetch: true, resultlimit: 'false', params:'', preprendIcon:'mdi-face-agent'
                            }"></AutocompleteMulti>
                        </v-col>
                        <v-col cols="6" md="6" class="pr-2">
                        </v-col>
                        <v-col cols="6" md="6" class="pr-2">
                            <DatePicker label="Executé le" :objectForm="reports.tasks" fieldName='min_wti_date_start'></DatePicker>
                        </v-col>
                        <v-col cols="6" md="6" class="pr-2">
                            <DatePicker label="Executé le" :objectForm="reports.tasks" fieldName='max_wti_date_start'></DatePicker>
                        </v-col>
                    </v-row>
                    </v-card-text>

                    <v-divider class="mt-5"></v-divider>
                    <v-card-actions>
                        <!-- <v-checkbox v-model="checkState.withConvention" label="Uniquement avec convention" class="mt-0"></v-checkbox> -->
                        <v-spacer></v-spacer>
                        <v-btn rounded color="success" :disabled="loading.getItem"
                            @click="downloadReport(
                                'tasks', 'per_page=false&extended=withentity,withworktime' )">
                            <v-icon left dark>mdi-content-save</v-icon>
                            <span class="hidden-sm-and-down">Telecharger</span>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        </v-card>
    </v-container>
</template>

<script>
import AutocompleteMulti from '@/components/ui/AutocompleteMulti.vue'
import DatePicker        from '@/components/ui/DatePicker'

export default {
    name: 'site-report',
    props: [], 
    mixins: [ ],
    components: {
        AutocompleteMulti, DatePicker
    },
    data: function () {
        return {
            loading: { getItem: false },
            listEntities: [],
            reports: {
                tasks : { min_tsk_date_plan: null, max_tsk_date_plan: null, sit_ent_id: [], tsk_tty_id: [], tsk_typ_id: [] }
            },
            datesRange: [
                { min_tsk_date_plan: null, max_tsk_date_plan: null }
            ]

        }
    },
    beforeMount:function (){

    },
    methods: {
        getFilterUrl(type){
            let filter = this.reports[type]
            let url    = ''
            for( var prop in filter ){
                if( filter[prop] !== null && filter[prop] !== undefined && filter[prop].length > 0 ){
                    url += (url.length) ? '&' : ''
                    url += prop + '=' + filter[prop]
                }
            }
            return url
        },
        rangeToUrl(table){
            let url = ''
            table.forEach(item => {
                for(const prop in item){
                    url += (item[prop]) ? '&' + prop + '[]=' + item[prop] : ''
                }
            })
            return url
        },
        downloadReport(report, option, method, fileName){
            method               = method || 'get'
            fileName             = fileName || 'rapport'
            this.loading.getItem = true
            let searchCriteria   = this.getFilterUrl(report)
            if( searchCriteria.length > 2 ){
                searchCriteria += '&' + option
            } else {
                searchCriteria  = option
            }
            this.$http.customRequest({ method: method, url: '/report/' + report + '/?' + searchCriteria, responseType: 'blob' } )
            .then( (response) => {
                this.$download(response.data, fileName + ".xlsx", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
                this.loading.getItem = false
            }).catch( (error) => {
                this.$root.$toast({ text: 'Erreur lors de l\'extraction !', error: error })
                this.loading.getItem = false
            })
        },
    }
}
</script>