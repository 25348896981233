<template>
    <v-container fluid class="pa-0">
        <PageHeader :contextStyle="contextStyle">
            <template #right><v-btn small rounded color="primary" href="#/equipments/0">Ajouter un matériel</v-btn></template>
        </PageHeader>
    
        <EquipmentForm v-if="( $route.params.equ_id !== undefined && parseInt( $route.params.equ_id ) >= 0 )"/>
        <v-card v-else class="ma-4 rounded-lg">
            <EquipmentsList />
        </v-card>
    
    </v-container>
    </template>
    
    <script>
    import PageHeader from '@/components/ui/PageHeader'
    import EquipmentsList  from '@/components/equipments/EquipmentList'
    import EquipmentForm   from '@/components/equipments/EquipmentForm'
    
    export default {
        name: 'equipments-page',
        props: ['contextStyle'],
        components: {
            PageHeader,
            EquipmentsList, EquipmentForm
        },
        data: function () {
            return {
            }
        },
        computed: { },
        created(){},
        mounted: function (){
    
        },
        methods: {
    
        },
        beforeDestroy() {
    
        },
    }
    </script>