import Vue           from 'vue'
import App           from '@/App.vue'
import router        from '@/services/router'
import vuetify       from '@/plugins/vuetify';
import { createPinia, PiniaVuePlugin } from 'pinia'
import { createPersistedState } from 'pinia-plugin-persistedstate'

import '@/plugins/download'
import '@/plugins/dayjs'
import '@/plugins/apexchart'
import '@/plugins/api'
import '@/plugins/build'
import '@/plugins/storage'
import '@/plugins/user'

import VueSignaturePad from 'vue-signature-pad';
Vue.use(VueSignaturePad);

Vue.use(PiniaVuePlugin)
export const pinia = createPinia()
const piniaStore = createPersistedState({
  key: id => `__persisted__${id}`
})
pinia.use(piniaStore)


// Event bus
Vue.prototype.$eventBus = new Vue({})

new Vue({
  vuetify,
  router,
  pinia,
  render: h => h(App)
}).$mount('#app')

// PWA features
// Whether we are running as an installed PWA or not.
//const isInstalledPWA = window.matchMedia('(display-mode: window-controls-overlay)').matches ||
//                       window.matchMedia('(display-mode: standalone)').matches;
// Register our service worker
if ("serviceWorker" in navigator) {
  navigator.serviceWorker.register("./sw.js");
}


/*if (!isInstalledPWA && !isSidebarPWA) {
  window.addEventListener('beforeinstallprompt', e => {
    // Don't let the default prompt go.
    e.preventDefault();

    // Instead, wait for the user to click the install button.
    aboutDialog.addEventListener('close', () => {
      if (aboutDialog.returnValue === "install") {
        e.prompt();
      }
    });
  });
} else {
  installButton.disabled = true;
}

addEventListener('appinstalled', () => {
  aboutDialog.close();
});*/