<template>
    <v-data-table v-bind:headers="headers" :items="itemList" item-key="id" :search="search"
        items-per-page-text="Lignes par page" hide-default-header
        :items-per-page-options="pagination.rowsPerItem" class="mt-0">
        <template v-slot:top>
            <v-row class="rounded-lg ma-0 pa-0" v-if="(searchBar == undefined || searchBar) || (addItemButton == undefined || addItemButton)">
                <v-col cols="6" md="6" class="pa-1">
                    <v-text-field v-if="(searchBar == undefined || searchBar)" append-icon="mdi-magnify" label="Recherche" outlined dense clearable single-line
                                hide-details v-model="search" class="pa-2 rounded-xl">
                    </v-text-field>
                </v-col>
                <v-col cols="1" md="1" class="">
                    <v-btn small rounded :color="(sitState == 'GEN01') ? 'grey' : 'blue'" 
                        @click="(sitState === 'GEN01') ? sitState='GEN01,GEN02' : sitState='GEN01'; fetchList();">Voir sites en veille</v-btn>
                </v-col>
                <v-col cols="5" md="5" class="text-right pa-1" v-if="(addItemButton == undefined || addItemButton)">
                    <router-link :to="(inSlider || inSlider == undefined) ? {
                            name: $route.matched[1].name + '-slide-site-edit', params: { ent_id: $route.params.ent_id, sit_id: 0 }
                        } : 0">
                        <v-btn small rounded color="primary">Ajouter un site</v-btn>
                    </router-link>
                </v-col>
            </v-row>
        </template>
        <template v-slot:[`item.sit_name`]="{ item }"><v-chip class="ma-2" color="secondary">{{ item.sit_name }}</v-chip><v-icon>mdi-arrow-right</v-icon></template>
        <template v-slot:[`item.ent_name`]="{ item }"><v-chip class="ma-2" outlined color="secondary"><v-icon>mdi-face-agent</v-icon>&nbsp;{{ item.ent_name }}</v-chip></template>
        <template v-slot:[`item.tsk_id_count`]="{ item }">
            <v-chip class="ma-2" outlined :color="(item.tsk_id_count > 0) ? 'green' : 'grey'" :title="(!item.sit_lat) ? 'site non géolocalisé' : 'Site(s)'" >
                <v-icon v-if="(!item.sit_lat)" color="red">
                    {{ (!item.sit_lat) ? 'mdi-map-marker-off-outline' : 'mdi-map-marker-multiple'}}
                </v-icon>
                <v-icon>mdi-traffic-cone</v-icon>&nbsp;{{ item.tsk_id_count }}
            </v-chip>
        </template>
        <template v-slot:[`item.sit_id`]="{ item }">
            <router-link :to="(inSlider == undefined || inSlider ) ? {
                name: $route.matched[1].name + '-slide-task', params: { ent_id: $route.params.ent_id, sit_id: item.sit_id, tsk_uid: 0 }
            } : '/tasks/' + 0">
                <v-btn v-if="$root.$permissions.Site.update" rounded color="green" elevation="0" dark title="Ajouter une tâche" class="mr-2">
                    <v-icon left  v-if="$root.$permissions.Site.update" >mdi-plus</v-icon>
                    <v-icon left  v-if="$root.$permissions.Site.update" >mdi-map-marker-multiple</v-icon>
                </v-btn>
            </router-link>

            <router-link :to="(inSlider || inSlider == undefined) ? {
                    name: $route.matched[1].name + '-slide-site-edit', params: { ent_id: $route.params.ent_id, sit_id: item.sit_id }
                } : item.sit_id">
                <v-btn v-if="$root.$permissions.Site.update" rounded color="orange" elevation="0" dark>
                    <v-icon left  v-if="$root.$permissions.Site.update" >{{item.sit_id}} mdi-pencil</v-icon> Fiche
                </v-btn>
            </router-link>
        </template>
        <template v-slot:no-data>
        <v-alert :value="( !itemList.lenght )" color="error" icon="mdi-alert">
            Aucun evenement
        </v-alert>
        </template>
    </v-data-table>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useSiteStore } from '@/stores/site'

export default {
    name: 'sites-list',
    props: [ 'sit_ent_id', 'searchBar', 'addItemButton', 'inSlider' ],
    computed: {
        ...mapState(useSiteStore, {itemList: 'itemList' })
    },
    data: function () {
        return {
            search: '',
            pagination: { totalItems: 0, rowsPerPage: 10, rowsPerItem: [15,25,35,{text:'Tout',value:-1}] },
            headers: [
                { text: 'Nom',    value: 'sit_name', align: 'left'},
                { text: 'Nom',    value: 'ent_name', align: 'left'},
                { text: 'Type',   value: 'sit_typ_id', align: 'left'},
                { text: 'Taches', value: 'tsk_id_count', align: 'left'},
                { text: 'Action', value: 'sit_id', align: 'right'},
            ],
            sitState: 'GEN01'
        }
    },
    async mounted () {
        this.fetchList()
    },
    methods: {
        ...mapActions(useSiteStore, {fetchItems: 'fetchItems'}),
        async fetchList(){
            let options = (this.sit_ent_id) ? '&sit_ent_id=' + this.sit_ent_id : ''
            await this.fetchItems('?per_page=false&extended=withentity,withcounttask' + options + '&sit_sta_id=' + this.sitState)
        },
        toSliderTask(tskId){
            let name = this.$route.matched[1].name.split('-')
            return {
                name: name[0] + '-' + name[1] + '-slide-task', params: {
                    ent_id: this.$route.params.ent_id, sit_id: this.$route.params.sit_id, tsk_uid: tskId
                }
            }
        }
    },
}
</script>