<template>
<v-container fluid class="pa-1">
    <v-toolbar class="elevation-0" height="48px">
        <v-toolbar-title >Détail du temps</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn v-if="(task.tsk_sta_id !== 'GEN03')"
            outlined rounded small :color="(true) ? 'grey' : 'green'" @click="openWorktime({ wti_id: 0 })">
            Ajouter<v-icon>mdi-playlist-edit</v-icon>
        </v-btn>
    </v-toolbar>
    <v-data-table v-bind:headers="headers" :items="itemList" item-key="sta_id"
        items-per-page-text="Lignes par page" :items-per-page-options="pagination.rowsPerItem" :loading="loading.getWorktime"> 
        <template v-slot:[`item.usr_firstName`]="{ item }">
            <v-chip v-if="item.wti_equ_id" class="ma-1 " small outlined color="secondary"><v-icon>mdi-tools</v-icon>&nbsp;{{ item.equ_name }}</v-chip>
            <v-chip v-else class="ma-1 " small outlined color="blue"><v-icon>mdi-account</v-icon>&nbsp;{{ item.usr_lastName.substring(0,1) + '. ' + item.usr_firstName }}</v-chip>
        </template>
        <template v-slot:[`item.wti_date`]="{ item }" >
            <v-chip class="ma-1 " small outlined color="secondary"><v-icon>mdi-clipboard-text-clock-outline</v-icon>&nbsp;{{ $date(item.wti_date_start).format('DD/MM/YYYY') }}</v-chip><br />
        </template>
        <template v-slot:[`item.wti_duration`]="{ item }" >
            <v-text-field outlined hide-details dense class="rounded-lg" v-model="item.wti_duration" type="time" :disabled="(item.wti_id > 0)"></v-text-field>
        </template>
        <template v-slot:[`item.wti_id`]="{ item }" >
            <v-icon v-if="item.wti_id && (taskItem.tsk_sta_id == 'TSK04' || taskItem.tsk_sta_id == 'TSK03')" 
                left color='orange' title="Editer" @click="openWorktime(item)">
                mdi-pencil
            </v-icon>
            <v-icon v-else-if="item.wti_id" :color="typeListById[item.wti_typ_id].typ_color" title="Voir" @click="openWorktime(item)">
                mdi-eye
            </v-icon>
            <v-icon v-if="(!item.wti_id)" 
                left color='green' title="Valider" @click="storeWortime(item)">
                mdi-content-save-plus-outline
            </v-icon>
        </template>
        <template slot="pageText" slot-scope="{ pageStart, pageStop }">
        De {{ pageStart }} à {{ pageStop }}
        </template>
        <template slot="no-data">
        <v-alert :value="( !loading.getWorktime )" color="error" icon="mdi-alert">
            Aucune saisie
        </v-alert>
        </template>
    </v-data-table>

    <v-dialog v-model="modalLayer" max-width="900" >
        <!-- <tab-layer v-if="modalLayer" :doc_id="doc_id" :switchModal="switchModal"></tab-layer> -->
    </v-dialog>
    <v-dialog v-model="modalWorktime" hide-overlay max-width="900" content-class="rounded-xxl micorise-v-card">
        <v-card color="grey lighten-4" min-width="350px" elevation="0" class="pa-0 elevation-0 rounded-xxl micorise-v-card" style="background-color: white">
        <v-toolbar color="secondary" dark class="elevation-1" height="50px">
            <v-btn icon><v-icon>mdi-chevron-down</v-icon></v-btn>
            <v-toolbar-title>Temps de travail</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon color="" @click.native="switchModal('modalWorktime', 0)"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="mt-2 pa-0">
            <WorktimeForm
                v-if="modalWorktime" :switchModal="switchModal"
                :wti_id="wti_id" :tsk_id="task.tsk_id">
            </WorktimeForm>
        </v-card-text>
        </v-card>
    </v-dialog>
</v-container>
</template>

<script>
import WorktimeForm from './WorktimeForm.vue'

import { mapState, mapActions, mapWritableState } from 'pinia'
import { useUserStore } from '@/stores/user'
import { useEquipmentStore } from '@/stores/equipment'
import { useWorkTimeStore } from '@/stores/worktime'
import { useTypeStore } from '@/stores/type'
import { useTaskStore } from '@/stores/task'

export default {
    name: 'worktime-list',
    props: ['task'],
    components: {
        WorktimeForm
    },
    computed: {
        ...mapWritableState(useWorkTimeStore, {workTimeitem: 'item' }),
        ...mapState(useWorkTimeStore,  {workTimeList: 'itemList' }),
        ...mapState(useUserStore,      {userList: 'itemList' }),
        ...mapState(useEquipmentStore, {equipmentList: 'itemList' }),
        ...mapState(useTypeStore,      {typeListByGroup: 'itemListByGroup', typeListById: 'itemListById' }),
        ...mapState(useTaskStore,      {taskItem: 'item' }),
    },
    beforeUpdate (){ },
    data: function () {
      return {
            loading: { uploadBtn: false, getWorktime: false },         // Enregistrement en cours...
            pagination: { totalItems: 0, rowsPerPage: 10, rowsPerItem: [15,25,35,{text:'Tout',value:-1}] },
            headers: [
            { text: 'Date',         align: 'left', value: 'wti_date'},
            { text: 'Utilisateur',  align: 'left', value: 'usr_firstName'},
            { text: 'Temps',           value: 'wti_duration' },
            { text: 'Action',       align: 'right', value: 'wti_id' }
            ],
            enableAdd: true,
            modalLayer : false,
            modalWorktime: false,
            itemList : [],
            wti_id: 0
      }
    },
    async mounted (){
        await this.fetchItems('?wti_tsk_id=' + this.task.tsk_id)
        this.computeWorktime()
    },
    methods: {
        ...mapActions(useWorkTimeStore, { fetchItems: 'fetchItems', updateItem: 'updateItem', storeItem: 'storeItem' }),
        async storeWortime(item){
            this.workTimeitem.wti_tsk_id     = this.taskItem.tsk_id
            this.workTimeitem.wti_usr_id     = item.usr_id
            this.workTimeitem.wti_equ_id     = item.wti_equ_id
            this.workTimeitem.wti_typ_id     = 'WTI01'
            this.workTimeitem.wti_date_start = new Date().toJSON().slice(0, 10)
            this.workTimeitem.wti_duration   = item.wti_duration
            await this.storeItem()
            await this.fetchItems('?wti_tsk_id=' + this.task.tsk_id)
            this.computeWorktime()
        },
        computeWorktime(){
            this.itemList = []
            let users = []
            let equipments = []
            this.workTimeList.forEach(element => {
                let equ = this.equipmentList.find(obj => { return obj.equ_id === element.wti_equ_id })
                element.equ_name = (element.wti_equ_id && equ) ? equ.equ_name : ''
                this.itemList.push(element)
                users.push(element.wti_usr_id)
                if( element.wti_equ_id ){
                    equipments.push(element.wti_equ_id)
                }
            })
            this.taskItem._pivot.users.forEach(element => {
                if( !users.includes(element) ){
                    let usr = this.userList.find(obj => { return obj.id === element })
                    this.itemList.push({
                        usr_firstName: (usr) ? usr.firstName : '!ERROR!',
                        usr_lastName : (usr) ? usr.lastName :  '!ERROR!',
                        usr_id       : element,
                        wti_equ_id   : null,
                        equ_name     : '',
                        wti_id       : 0,
                        wti_usr_id   : element,
                        wti_typ_id   : 'WTI01',
                        wti_duration : '00:00',
                        wti_date     : '2024/01/01'
                    })
                }
            })
            this.taskItem._pivot.equipments.forEach(element => {
                if( !equipments.includes(element) ){
                    let equ = this.equipmentList.find(obj => { return obj.equ_id === element })
                    let usr = this.userList.find(obj => { return obj.id === this.taskItem.tsk_usr_id_created })
                    this.itemList.push({
                        usr_firstName: (usr) ? usr.firstName : '!ERROR!',
                        usr_lastName : (usr) ? usr.lastName :  '!ERROR!',
                        usr_id       : this.taskItem.tsk_usr_id_created,
                        wti_equ_id   : element,
                        equ_name     : (equ) ? equ.equ_name : '!ERROR!',
                        wti_id       : 0,
                        wti_typ_id   : 'WTI01',
                        wti_duration : '00:00',
                        wti_date     : '2024/01/01'
                    })
                }
            })
        },
        openWorktime(item){
            this.wti_id = item.wti_id
            this.switchModal('modalWorktime', 1)
        },
        async switchModal(name, mode, refresh){
            name    = name || 'modalWorktime'
            mode    = mode || 0
            refresh = refresh || false
            this[name] = mode
            if( refresh ){
                await this.fetchItems('?wti_tsk_id=' + this.task.tsk_id)
                this.computeWorktime()
            }
        }
    }
}
</script>