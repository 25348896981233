<template>
    <v-container fluid class="pa-0">
    <!-- Formulaire -->
    <v-card fluid class="ma-2 pa-3 elevation-0 rounded-xxl micorise-v-card">
        <v-card-title >
            Matériel
            <v-spacer></v-spacer>
            <v-chip outlined color="secondary">
                    <v-icon left>mdi-information-outline</v-icon>Modification: {{ $date(item.equ_updated_at).format('DD MMMM YYYY') }}
            </v-chip>
        </v-card-title>
        <v-form ref="form" v-model="validForm" lazy-validation>
        <v-row no-gutters>
            <v-col cols="12" md="8" class="pr-2">
                <v-text-field :rules="nameRules" outlined dense class="rounded-lg" label="Nom" v-model="item.equ_name" ></v-text-field>
            </v-col>
            <v-col cols="1" md="1" class="pr-2">
            </v-col>
            <v-col cols="12" md="4" class="pr-2">
                <v-text-field outlined dense class="rounded-lg" label="Série" v-model="item.equ_serial"></v-text-field>
            </v-col>
            <v-col cols="12" md="4" class="pr-2">
                
            </v-col>
        </v-row>
        </v-form>
        <v-card-actions>
            <v-btn class="rounded-xl" large color="red" v-if="item.uid" @click="deleteItem(item)"><v-icon title="Supprimer">mdi-delete</v-icon></v-btn>
            <v-spacer></v-spacer>
            <v-btn class="rounded-xl" large color="success" :loading="loading.fetchData" @click.native="saveItem()" :disabled="(loading.fetchData || !validForm)">Enregistrer</v-btn>
            <v-btn class="rounded-xl" large color="primmary" href="/#/equipments/">Retour</v-btn>
        </v-card-actions>
    </v-card>
    <v-dialog v-model="modalGeolocate" hide-overlay max-width="900" content-class="rounded-xxl micorise-v-card">
        <MapGeolocate v-if="modalGeolocate" :latLng="{lat: item.ent_lat, lng: item.ent_lng}" :switchGeolocate="switchGeolocate" />
    </v-dialog>
    </v-container>
</template>
    
<script>
//import AutocompleteMulti from '@/components/ui/AutocompleteMulti'
import MapGeolocate from '@/components/map/MapGeolocatePopup'

import { mapWritableState, mapActions } from 'pinia'
import { useEquipmentStore } from '@/stores/equipment'

export default {
    name: 'equipments-edit',
    components: { MapGeolocate }, //AutocompleteMulti,
    computed: {
        ...mapWritableState(useEquipmentStore, {item: 'item' }),
    },
    watch: { },
    data: function () {
        return {
            loading: { fetchData: true },
            listEntities: [], listAgencies: [],
            search: null, searchAddress: null, searchBoxClosed: true, addressTab: [], timeOutHandle: 'off',
            modalGeolocate: false,
            validForm: true,
            nameRules: [ v => !!v || 'Le champ doit être renseigné', v => (v && v.length <= 100) || 'Doit être inférieur à 100 charactères', ],
            emailRules: [ v => !!v || 'E-mail requis', v => /.+@.+\..+/.test(v) || 'E-mail doit être valide', ],
        }
    },
    async mounted () {
        await this.fetchItem( this.$route.params.equ_id, '?ent_sta_id=GEN01' )
        this.loading.fetchData = false
    },
    methods: {
        ...mapActions(useEquipmentStore, {fetchItem: 'fetchItem', updateItem: 'updateItem', storeItem: 'storeItem'}),
        async saveItem () {
            await this.$refs.form.validate()
            if( this.validForm ){
                // Enregistrement du formulaire
                this.loading.fetchData = true
                if( parseInt( this.$route.params.equ_id ) > 0 ){
                    this.updateItem()
                } else {
                    this.storeItem()
                }
                this.$router.push({ path: '/equipments' })
            }
        },
    }
}
</script>